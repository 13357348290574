import { Theme } from '@mui/material/styles'

export default function Divider(theme: Theme) {
  return {
    MuiModal: {
      styleOverrides: {
        root: {
          zIndex: 9999
        }
      }
    }
  }
}
