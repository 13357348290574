import React, { useState, useEffect, useMemo, useRef } from 'react'
import { useHistory, matchPath } from 'react-router-dom'
import { useDebounce } from 'use-debounce'
import cache from 'helpers/cache'
import { UserData, HealthChecksDataContext, SiteUser } from './context'
import { SiteDataContext } from 'state/context/site/siteCtx'
import { ReportParams, FetchStatus, HealthCheckReport } from './types'
import { useDeepCompareEffectNoCheck } from 'use-deep-compare-effect'
import errorLogger from './formLogger'
import { handle401 } from './context'
import { FeatureFlagContext } from 'components/FeatureFlag'
import { getApi } from 'helpers/data'
import { SummaryResponse } from 'helpers/types'
import {
  normalizeDateForRequest,
  getStartOfLastMonth,
  getEndOfLastMonth
} from 'helpers/dates'
import useIsAdmin from 'helpers/hooks/useIsAdmin'

export const useSort = <T,>(initial: T): [T, boolean, (v: T) => void] => {
  const [sortBy, setSortBy] = useState<T>(initial)
  const [reverse, setReverse] = useState(true)

  const handleSort = (value: T) => {
    if (sortBy === value) {
      return setReverse(!reverse)
    }
    setSortBy(value)
    setReverse(true)
  }

  return [sortBy, reverse, handleSort]
}

export const useAuthContext = (token: string, userData: UserData): any => {
  const authContext = {
    apiParams: {
      headers: {
        Authorization: `Bearer ${token}`
      },
      baseURL: process.env.REACT_APP_API
    },
    onLogout: () => {
      localStorage.clear()
      sessionStorage.clear()
      if (caches) {
        caches.keys().then((cacheNames) => {
          cacheNames.forEach((cacheName) => {
            caches.delete(cacheName)
          })
        })
      }
      window.location.href = '/'
    },
    onExpire: () => {},
    onRenew: () => {},
    onResponse: async (response: { status: number }) => {
      if (response?.status === 401) {
        handle401()
      }
    },
    token,
    userData
  }
  return authContext
}

/**
 * Returns if a user is using a mobile device.
 */
export function useIsMobile(breakpoint = 768) {
  const [isMobile, setIsMobile] = React.useState(window.innerWidth < breakpoint)

  const update = () => setIsMobile(window.innerWidth < breakpoint)

  React.useEffect(() => {
    window.addEventListener('resize', update)
    return () => window.removeEventListener('resize', update)
  })

  return isMobile
}

/**
 * Returns a site's live_on date.
 */
export function useLiveOnDate() {
  const siteData = React.useContext(SiteDataContext)

  const live_on = siteData?.loyalty?.live_on

  // '' is the default value. If it's the value,
  // we want to return undefined instead of null
  // so consumers can make a distinction between
  // a site without a live_on date and sites that
  // haven't finished loading.
  if (live_on === '') return undefined

  if (!live_on) return null
  return new Date(live_on.replace(/-/g, '/'))
}

interface UseRequestArgs<T, K = ReportParams> {
  initialParams: K
  fetchData(params: K): Promise<T>
  /** Set to a a number to debounce requests. If not provided, will invoke function immediately. */
  debounce?: number
  maxRetries?: number
  options?: {
    leading?: boolean
    trailing?: boolean
    maxWait?: number
  }
}

/**
 * TODO: Docs
 */
export const useRequest = <T, K = ReportParams>({
  fetchData,
  initialParams,
  debounce = 0,
  maxRetries = 1
}: UseRequestArgs<T, K>) => {
  const [params, setParams] = React.useState<K>(initialParams)
  const [debouncedParams] = useDebounce(params, debounce)
  const [fetchStatus, setFetchStatus] = React.useState<FetchStatus>('initial')
  const [data, setData] = React.useState<T | null>(null)
  const [error, setError] = React.useState<any>(null)
  const [retryKey, setRetryKey] = React.useState(0)

  const retry = () => {
    setError(null)
    setRetryKey((k) => k + 1)
  }
  const canRetry = retryKey < maxRetries

  // If initial params change, update internal params.
  useDeepCompareEffectNoCheck(() => {
    setParams(initialParams)
  }, [initialParams])

  // When internal params update, fetch data.
  useDeepCompareEffectNoCheck(() => {
    let cancel = false

    // We want to leave a reasonable amount of time for the
    // request to resolve before we show an indicator to the user
    const timeout: number = window.setTimeout(
      () => setFetchStatus('fetching'),
      500
    )

    fetchData(debouncedParams)
      .then((data: any) => {
        if (cancel) return
        clearTimeout(timeout)
        setData(data)
        setError(null)
        setFetchStatus('fetched')
      })
      .catch((err: any) => {
        if (err.response?.status === 401) {
          if (
            err.response?.data.error === 'Google OAuth2::Error' ||
            err?.response?.data?.error === 'Google Authorization Failed'
          ) {
            // do nothing
          } else {
            console.log('response', err.response)
            handle401()
          }
        }

        errorLogger(err)
        setError(err)
        setFetchStatus('error')
      })

    return () => {
      clearTimeout(timeout)
      cancel = true
    }
  }, [debouncedParams, retryKey])

  return {
    params,
    debouncedParams,
    setParams,
    fetchStatus,
    data,
    error,
    retry,
    canRetry
  }
}

type UseUpdateArgs<T, K, P> = (body: T, extras: P) => Promise<K>

interface UseUpdateOptions {
  throwOnError?: boolean
}

/**
 * Given a function returning a promise, return
 * a status, error, and result of action.
 *
 * The function can accept a single argument, plus
 * an object of any extra arguments that can be defined
 * when the hook is defined. This is useful for defining
 * parameters that won't change depending on the value
 * passed into the callback.
 */
export const useUpdate = <T, K, P>(
  action: UseUpdateArgs<T, K, P>,
  extras: P,
  { throwOnError }: UseUpdateOptions = {}
) => {
  const [updateStatus, setUpdateStatus] = React.useState<FetchStatus>('initial')
  const [response, setResponse] = React.useState<K | null>(null)
  const [updateError, setUpdateError] = React.useState<Error | null>(null)

  // Under specific scenarios and due to the asynchronous call to the action
  // callback (await action(data, extras)) some of the functions that change the
  // internal state of this component (setResponse for example) are happening when
  // this component is unmounted  causing React to warn: Can't perform a React state
  // update on an unmounted component. While not the best of the solutions and
  // to reduce any posibility of introducing breaking changes,
  // the code simply makes sure to only change the state when the component is mounted
  const isMounted = useRef(true)

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  const execute = React.useCallback(
    async (data: T) => {
      try {
        setUpdateStatus('fetching')
        const res = await action(data, extras)
        if (isMounted.current) {
          setResponse(res)
          setUpdateStatus('fetched')
        }
        return res
      } catch (err) {
        setUpdateError(err as Error)
        setUpdateStatus('error')
        if (throwOnError) {
          throw err
        }
      }
    },
    [extras, action, throwOnError]
  )
  const reset = React.useCallback(() => {
    setUpdateStatus('initial')
    setResponse(null)
    setUpdateError(null)
  }, [])

  return { updateStatus, response, updateError, execute, reset }
}

/**
 * Returns true if the site is a Pubnation site.
 */
export function useIsPubnation() {
  const { offering_id } = React.useContext(SiteDataContext)
  return offering_id === 2
}

/**
 * Returns getter and setter for UI cache based on key.
 *
 * Takes a second argument, an array of boolean values.
 * If any of them are false, don't touch the cache.
 * This allows us to control precisely when caches are set,
 * in cases where the key of a cache depends on a value that
 * changes at runtime.
 */
export function useUICache(key: string, requirements: any[] = []) {
  const [value, setValue] = React.useState(cache.get(key))

  useEffect(() => {
    const ignore = requirements.some((r) => !r)
    if (!ignore) {
      if (value !== undefined) {
        // If the value exists, set the cache.
        cache.set({ [key]: value })
      } else {
        // Otherwise, set the local state based on the cache.
        setValue(cache.get(key))
      }
    }
  }, [key, value, requirements])

  return [value, setValue]
}

/**
 * Return a boolean value that we can use to hide things
 * for sites with "manage my account" turned on.
 * Return false for admins, since they need access to these things.
 */
export function useManageAccount() {
  const isAdmin = useIsAdmin()
  const { premiere_manage_account } = React.useContext(SiteDataContext)

  if (process.env.NODE_ENV === 'development') {
    // If we have the react app user premiere managed env var defined, return true
    if (process.env.REACT_APP_USER_PREMIERE_MANAGED === 'true') return true
  }

  return premiere_manage_account && !isAdmin
}

/**
 * Returns a boolean value that we can use to check if a feature is enabled.
 * - Returns true if the flag exists on either the user or site record.
 * - Returns null if there are no flags on user record (this case currently only
 * happens while the user record is loading).
 */
export function useFeatureFlag(flag: string | undefined) {
  const { features = [] } = React.useContext(SiteDataContext)
  const { flags } = React.useContext(FeatureFlagContext)

  if (!flags.length) return null

  return !flag || flags.includes(flag) || features?.includes(flag)
}

declare global {
  interface Window {
    gtag?: (
      key: string,
      trackingId: string,
      config: {
        page_path?: string
        user_role?: string
        earnings_segment?: string
      }
    ) => void
  }
}

async function fetchData({ siteId, roles, site_users }: any) {
  if (!siteId) return null

  // Each site has specific roles for specific users
  const siteUserReportingPermit = site_users?.some((site_user: SiteUser) => {
    return (
      (site_user.site_id === Number(siteId) &&
        site_user.roles.includes('owner')) ||
      (site_user.site_id === Number(siteId) &&
        site_user.roles.includes('reporting')) ||
      (site_user.site_id === Number(siteId) &&
        site_user.roles.includes('post_termination_new_owner'))
    )
  })

  // In addition to site user specific roles we check for roles attached to the user
  // object. If none of these are included in the roles object then we return and don't
  // make any requests
  if (!siteUserReportingPermit && !roles.includes('admin')) return

  const api = getApi()
  const lastMonthParams = {
    start_date: normalizeDateForRequest(getStartOfLastMonth()),
    end_date: normalizeDateForRequest(getEndOfLastMonth())
  }

  const req = await api.get<SummaryResponse>(
    `/sites/${siteId}/reports/metrics/summary`,
    {
      params: lastMonthParams
    }
  )

  return req.data.summary
}

/**
 * Utility that allows the app to hook into the listen method of the useHistory hook at
 * init phase as well as every time trackingId and listen change
 * in order to run the required gtag global method on every route transition for google analytics purposes
 */

export const useTracking = (
  trackingId: string | undefined = process.env.REACT_APP_GA_MEASUREMENT_ID,
  userData?: UserData | null
) => {
  const { listen } = useHistory()
  // Using the useMemo hook here to follow react-router best practice for function components: https://github.com/ReactTraining/react-router/issues/7059
  const match = useMemo(
    () =>
      matchPath<{ siteId: string }>(window.location.pathname, {
        path: '/sites/:siteId',
        exact: false,
        strict: false
      }),
    []
  )

  const siteId = match?.params.siteId
  const { data } = useRequest({
    fetchData: fetchData,
    initialParams: {
      siteId: siteId,
      roles: userData?.roles,
      site_users: userData?.site_users
    }
  })

  useEffect(() => {
    const cleanup = listen((location: any) => {
      if (!window.gtag) return
      if (!trackingId) return
      if (location.hostname === 'localhost') return

      const basicTitle = (title: string[]) => {
        if (title[0].includes('Home') || title[0].includes('Dashboard')) {
          return 'Dashboard'
        }

        return title[0]
      }

      window.gtag('config', trackingId, {
        page_path: basicTitle(document.title.split(' |'))
      })
    })

    return cleanup
  }, [trackingId, listen])

  useEffect(() => {
    if (!window.gtag) return
    if (window.location.hostname === 'localhost') return

    if (userData && userData.roles.includes('admin')) {
      window.gtag('set', 'user_properties', {
        user_role: 'admin'
      })
    } else {
      let earnings = '0'

      if (data) {
        if (data?.earnings < 500) {
          earnings = `<${500}`
        } else if (data?.earnings < 1000) {
          earnings = `<${1000}`
        } else if (data?.earnings < 5000) {
          earnings = `<${5000}`
        } else if (data?.earnings < 10000) {
          earnings = `<${10000}`
        } else {
          earnings = `${10000}+`
        }
      }

      if (userData && userData.premiere_sites) {
        window.gtag('set', 'user_properties', {
          user_role: userData.premiere_sites[0] ? 'premiere' : 'publisher',
          earnings_segment: data?.earnings ? earnings : '0'
        })
      }
    }
  }, [data, userData])
}

export function usePreviousValue<T>(
  track: T,
  initialValue: T | null = null
): T | null {
  const ref = React.useRef<T | null>(initialValue)
  React.useEffect(() => {
    ref.current = track
  })
  return ref.current
}

/**
 * Returns true if any value of a site's last 3 days of health checks data contain missing data (zeros or nulls)
 */
export function useIsAnyDataMissingHCLast3Days() {
  const healthCheckData = React.useContext(HealthChecksDataContext)
  return healthCheckData?.health_checks?.some((item: HealthCheckReport) =>
    Object.values(item)
      .filter((item) => typeof item === 'number')
      .some((item) => item === null || item === 0)
  )
}

/**
 * Returns true if ALL of a site's last 3 days of health checks data contains missing data (zeros or nulls)
 */
export function useIsAllDataMissingHCLast3Days() {
  const healthCheckData = React.useContext(HealthChecksDataContext)
  return healthCheckData?.health_checks?.every((item: HealthCheckReport) =>
    Object.values(item)
      .filter((item) => typeof item === 'number')
      .every((item) => item === null || item === 0)
  )
}

export { default as useClickwrap } from './hooks/useClickwrap'
