import Axios, { AxiosError } from 'axios'
import { CLICKWRAP_ACCESS_ID, CLICKWRAP_ACCESS_TOKEN } from 'helpers/constants'
import React, { useMemo } from 'react'
import { matchPath } from 'react-router-dom'

interface ClickwrapCustomerData {
  site_id: string
  user_id: string
  name: string
  email: string
  offering: 'Mediavine' | 'Pubnation'
  site_name: string
}

/**
 * Generic hook used for interacting with clickwrap api.
 * @param param0 {gkey, contractId}
 * @returns object with necessary fields for interacting with api.
 */
export const useClickwrap = ({
  gkey,
  contractId
}: {
  gkey: string
  contractId: number
}) => {
  // Using the useMemo hook here to follow react-router best practice for function components: https://github.com/ReactTraining/react-router/issues/7059
  const match: any = useMemo(
    () =>
      matchPath(window.location.pathname, {
        path: '/sites/:siteId',
        exact: false,
        strict: false
      }),
    []
  )

  const siteId = parseInt(match?.params.siteId)
  const [error, setError] = React.useState<string | null>(null)

  const acceptTos = async (et: string, cus: ClickwrapCustomerData) => {
    try {
      const contractResp = await Axios.get(
        `https://api.pactsafe.com/v1.1/contracts/${contractId}`,
        {
          headers: {
            accept: 'application/json',
            authorization: `Bearer ${CLICKWRAP_ACCESS_TOKEN}`
          }
        }
      )

      const version = contractResp.data.data.published_version

      const options = {
        method: 'POST',
        url: 'https://pactsafe.io/send',
        params: {
          sid: CLICKWRAP_ACCESS_ID,
          sig: `${siteId}`,
          vid: version,
          et: et,
          cus: JSON.stringify(cus),
          gkey
        },
        headers: { accept: 'application/json' }
      }

      await Axios.request(options)
    } catch (error) {
      if (error instanceof AxiosError) {
        setError('Something went wrong, please contact Support.')
        throw error
      }
    }
  }

  return {
    acceptTos,
    error
  }
}

interface FetchSignatureParams {
  siteId: number
  gkey: string
}

export async function fetchSignature({
  siteId,
  gkey
}: FetchSignatureParams): Promise<boolean> {
  const res = await Axios.get(
    `https://pactsafe.io/latest?sid=${CLICKWRAP_ACCESS_ID}&sig=${siteId}&gkey=${gkey}`,
    { method: 'GET', headers: { accept: 'application/json' } }
  )
  return Object.values(res.data).every((value) => value) as boolean
}

export default useClickwrap
