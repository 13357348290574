import React from 'react'
import { Grid, H5, Network } from '@mediavine/ui'
import LoginModal from 'components/modals/LoginModal'
import ConfirmAddressForm from 'components/ConfirmAddressForm'
import { SiteDataContext } from 'state/context/site/siteCtx'
import { useIsMobile } from 'helpers/hooks'

export default function ConfirmAddressModal() {
  const { id, address_exists, address_expired } =
    React.useContext(SiteDataContext)
  const {
    userData: { roles }
    // @ts-ignore
  } = React.useContext<UserContextProps>(Network.JWTContext)
  const isMobile = useIsMobile()

  return (
    <LoginModal
      id="confirm-address"
      heading="We need your payment information."
      height={isMobile ? '80vh' : 'auto'}
      width={isMobile ? undefined : 'auto'}
      open={
        ((address_exists === false || address_expired) &&
          !roles.includes('admin')) ||
        false
      }
    >
      <Grid>
        <H5 id="confirm-address-header">
          {address_exists
            ? 'Please verify your mailing address.'
            : 'Please add your mailing address.'}
        </H5>
        <ConfirmAddressForm id={id} />
      </Grid>
    </LoginModal>
  )
}
