import { v4 as uuidV4 } from 'uuid'
import {
  getLastActivityTime,
  getMinMaxOffsets,
  initActivityListeners,
  resetMaxScrolls
} from './activityListeners'

const SNOWPLOW_SCHEMA =
  'iglu:com.snowplowanalytics.snowplow/payload_data/jsonschema/1-0-4'

interface SnowplowTrackerParams {
  appId: string
  appVersion: string
  batchSize?: number
  collectorUrl: string
  pagePingFrequency?: number
  trackerNamespace: string
}

interface SendSnowplowEventParams {
  appVersion?: string | null
  action: string
  category: string
  [key: string]: any
}

interface SnowplowRequestParams {
  action: string
  appId: string
  browserLanguage?: string
  category: string
  collectorUrl: string
  colorDepth?: string
  cookieEnabled?: string
  documentCharset?: string
  documentSize?: string
  domainUserId?: string
  property?: string
  readerId?: string
  screenSize?: string
  sessionId?: string
  snowplowSchema: string
  timezone?: string
  trackerNamespace?: string
  visitCount?: number
  windowSize?: string
}

interface SnowplowPagePingRequestParams {
  appId: string
  collectorUrl: string
  domainUserId?: string
  minXOffset?: string
  maxXOffset?: string
  minYOffset?: string
  maxYOffset?: string
  pageTitle?: string
  sessionId?: string
  snowplowSchema: string
  trackerNamespace?: string
}

class SnowplowTracker {
  appId: string
  appVersion: string
  batchSize: number
  collectorUrl: string
  isBeaconAvailable: boolean
  lastSentPageVisibilityHidden: number | null
  outQueue: Record<string, unknown>[]
  pagePingFrequency?: number
  trackerNamespace: string

  constructor({
    batchSize = 1,
    collectorUrl,
    appId,
    trackerNamespace,
    appVersion,
    pagePingFrequency
  }: SnowplowTrackerParams) {
    this.appId = appId
    this.appVersion = appVersion
    this.batchSize = batchSize
    this.collectorUrl = collectorUrl
    if (collectorUrl === '' && process.env.NODE_ENV !== 'test')
      console.error(
        'COLLECTOR URL endpoint variables are not defined, snowplow events wont work'
      )
    this.isBeaconAvailable = Boolean(window.navigator.sendBeacon)
    this.outQueue = []
    this.pagePingFrequency = pagePingFrequency
    this.trackerNamespace = trackerNamespace
    this.lastSentPageVisibilityHidden = null

    // Disabled for the moment, we might want to track these events in the future
    // if (this.pagePingFrequency) {
    //   this.initializePagePingInterval()
    // }

    // if (this.isBeaconAvailable) {
    //   // https://developer.mozilla.org/en-US/docs/Web/API/Navigator/sendBeacon#sending_analytics_at_the_end_of_a_session
    //   document.addEventListener('visibilitychange', () => {
    //     if (document.visibilityState === 'hidden') {
    //       this.sendEvent({
    //         action: 'page_visibility_hidden',
    //         category: 'pageActions'
    //       })
    //       this.sendQueue()
    //     }
    //   })
    //   window.addEventListener('pagehide', () => {
    //     this.sendQueue()
    //   })
    // }
  }

  enqueueEvent(event: Record<string, unknown>) {
    if (this.isBeaconAvailable) {
      this.outQueue.push(event)
      if (this.outQueue.length >= 1) {
        this.sendQueue()
      }
    } else {
      this.snowplowRequest([event])
    }
  }

  sendQueue() {
    if (this.outQueue.length === 0) {
      return
    }
    const stm = new Date().getTime().toString()
    this.outQueue.forEach((evt) => {
      evt.stm = stm
    })
    const body = {
      schema: SNOWPLOW_SCHEMA,
      data: this.outQueue
    }
    const blob = new Blob([JSON.stringify(body)], { type: 'application/json' })
    const beaconStatus = navigator.sendBeacon(this.collectorUrl, blob)
    if (beaconStatus) {
      this.outQueue = []
    } else {
      this.snowplowRequest(this.outQueue)
      this.outQueue = []
    }
  }

  initializePagePingInterval() {
    initActivityListeners()
    let lastActivityTime = getLastActivityTime()
    let prevLastActivityTime = lastActivityTime
    this.sendPagePingEvent()

    resetMaxScrolls()

    setInterval(() => {
      lastActivityTime = getLastActivityTime()

      if (lastActivityTime !== prevLastActivityTime) {
        this.sendPagePingEvent()
        prevLastActivityTime = lastActivityTime
        resetMaxScrolls()
      }
    }, this.pagePingFrequency)
  }

  sendPagePingEvent() {
    const pageTitle = document.title
    const { domainUserId, sessionId } = getAndUpdateSnowplowUserInfo()
    const { minXOffset, maxXOffset, minYOffset, maxYOffset } =
      getMinMaxOffsets()

    const body = this.getPagePingBody({
      appId: this.appId,
      collectorUrl: this.collectorUrl,
      domainUserId,
      minXOffset: String(minXOffset),
      maxXOffset: String(maxXOffset),
      minYOffset: String(minYOffset),
      maxYOffset: String(maxYOffset),
      pageTitle,
      sessionId,
      snowplowSchema: SNOWPLOW_SCHEMA,
      trackerNamespace: this.trackerNamespace
    })
    this.enqueueEvent(body)
  }

  sendEvent({
    action,
    userId,
    siteId,
    appVersion,
    category,
    readerId,
    ...rest
  }: SendSnowplowEventParams) {
    const browserLanguage = navigator.language
    const documentCharset = document.characterSet

    const screenSize = makeSizeString(window.screen.width, window.screen.height)
    const windowSize = makeSizeString(window.innerWidth, window.innerHeight)
    const documentSize = makeSizeString(
      document.body.scrollWidth,
      document.body.scrollHeight
    )
    const colorDepth = String(window.screen.colorDepth)
    const timezone = getTimezone()
    const property = JSON.stringify({
      appVersion: appVersion || this.appVersion,
      userId: userId,
      siteId: siteId,
      ...rest
    })
    const cookieEnabled = navigator.cookieEnabled ? '1' : '0'
    const { domainUserId, visitCount, sessionId } =
      getAndUpdateSnowplowUserInfo()
    const body = this.getEventBody({
      action,
      appId: this.appId,
      browserLanguage,
      category,
      collectorUrl: this.collectorUrl,
      colorDepth,
      cookieEnabled,
      documentCharset,
      documentSize,
      domainUserId,
      property,
      readerId,
      screenSize,
      sessionId,
      snowplowSchema: SNOWPLOW_SCHEMA,
      timezone,
      trackerNamespace: this.trackerNamespace,
      visitCount,
      windowSize
    })
    this.enqueueEvent(body)
  }

  sendPageVisibilityHidden() {
    if (
      this.lastSentPageVisibilityHidden &&
      Date.now() - this.lastSentPageVisibilityHidden < 5000
    ) {
      return
    }
    this.lastSentPageVisibilityHidden = Date.now()
    this.sendEvent({
      action: 'page_visibility_hidden',
      category: 'pageActions'
    })
  }

  getSnowplowCommonFields() {
    const pageUrl = window.location.href
    const platform = 'web'
    const referrer = document.referrer
    const timestamp = String(Date.now())
    const trackerVersion = '0.1.0'
    const useragent = navigator.userAgent
    return {
      timestamp,
      platform,
      referrer,
      trackerVersion,
      pageUrl,
      useragent
    }
  }

  getEventBody({
    action,
    appId,
    browserLanguage,
    category,
    colorDepth,
    cookieEnabled,
    documentCharset,
    documentSize,
    domainUserId,
    property,
    readerId,
    screenSize,
    sessionId,
    timezone,
    trackerNamespace,
    visitCount,
    windowSize
  }: SnowplowRequestParams) {
    const {
      timestamp,
      platform,
      referrer,
      trackerVersion,
      pageUrl,
      useragent
    } = this.getSnowplowCommonFields()
    return {
      aid: appId,
      cd: colorDepth,
      cookie: cookieEnabled,
      cs: documentCharset,
      dtm: timestamp,
      ds: documentSize,
      duid: domainUserId,
      e: 'se',
      eid: uuidV4(),
      lang: browserLanguage,
      p: platform,
      refr: referrer,
      res: screenSize,
      se_ca: category,
      se_ac: action,
      se_pr: property,
      sid: sessionId,
      tna: trackerNamespace,
      tv: trackerVersion,
      tz: timezone,
      ua: useragent,
      uid: readerId || undefined,
      url: pageUrl,
      vid: String(visitCount),
      vp: windowSize
    }
  }

  getPagePingBody({
    appId,
    domainUserId,
    minXOffset,
    maxXOffset,
    minYOffset,
    maxYOffset,
    pageTitle,
    sessionId,
    trackerNamespace
  }: SnowplowPagePingRequestParams) {
    const {
      timestamp,
      platform,
      referrer,
      trackerVersion,
      pageUrl,
      useragent
    } = this.getSnowplowCommonFields()

    return {
      aid: appId,
      dtm: timestamp,
      duid: domainUserId,
      e: 'pp',
      eid: uuidV4(),
      p: platform,
      page: pageTitle,
      pp_mix: minXOffset,
      pp_max: maxXOffset,
      pp_miy: minYOffset,
      pp_may: maxYOffset,
      refr: referrer,
      sid: sessionId,
      tv: trackerVersion,
      tna: trackerNamespace,
      url: pageUrl,
      ua: useragent
    }
  }

  async snowplowRequest(data: Record<string, unknown>[]) {
    try {
      const resp = await fetch(this.collectorUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify({
          schema: SNOWPLOW_SCHEMA,
          data
        })
      })
      return resp
    } catch (e) {
      if (process.env.NODE_ENV === 'development') {
        console.error(e)
      }
    }
  }
}

const getTimezone = () => {
  try {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    return timezone
  } catch (e) {
    console.error(e)
  }
}

const makeSizeString = (width: number, height: number) => {
  return `${width}x${height}`
}

interface StoredUserInfo {
  domainUserId: string
  sessionId: string
  visitCount: number
  lastEventTimestamp: number
}

function initializeStoredUserInfo() {
  const initialData: StoredUserInfo = {
    domainUserId: uuidV4(),
    sessionId: uuidV4(),
    visitCount: 1,
    lastEventTimestamp: Date.now()
  }
  try {
    localStorage.setItem('growMeSnowplowUserInfo', JSON.stringify(initialData))
  } catch (e) {
    console.log('error is ', e)
  }
  return initialData
}

function updateStoredUserInfo(data: Partial<StoredUserInfo>): StoredUserInfo {
  try {
    const currentString = localStorage.getItem('growMeSnowplowUserInfo')
    if (!currentString) {
      return initializeStoredUserInfo()
    }
    const current = JSON.parse(currentString)
    const updatedInfo: StoredUserInfo = {
      ...current,
      ...data
    }
    localStorage.setItem('growMeSnowplowUserInfo', JSON.stringify(updatedInfo))
    return updatedInfo
  } catch (e) {
    return initializeStoredUserInfo()
  }
}

const THIRTY_MINS_MS = 1800000

export function getSnowplowUserInfo(): StoredUserInfo {
  try {
    const storedUserInfoString = localStorage.getItem('growMeSnowplowUserInfo')
    if (!storedUserInfoString) {
      const initialValue = initializeStoredUserInfo()
      return initialValue
    }
    return JSON.parse(storedUserInfoString)
  } catch (e) {
    return initializeStoredUserInfo()
  }
}

function getAndUpdateSnowplowUserInfo(): StoredUserInfo {
  try {
    let storedUserInfo = getSnowplowUserInfo()
    if (
      storedUserInfo.sessionId &&
      Date.now() - storedUserInfo.lastEventTimestamp < THIRTY_MINS_MS
    ) {
      storedUserInfo = updateStoredUserInfo({
        lastEventTimestamp: Date.now()
      })
      return storedUserInfo
    } else {
      storedUserInfo = updateStoredUserInfo({
        sessionId: uuidV4(),
        lastEventTimestamp: Date.now(),
        visitCount: storedUserInfo.visitCount + 1
      })
      return storedUserInfo
    }
  } catch {
    // Re-initialize if the data becomes corrupt somehow
    return initializeStoredUserInfo()
  }
}

export default SnowplowTracker
