import React from 'react'
import { Network } from '@mediavine/ui'
import { UserData } from 'helpers/context'
import { useFeatureFlag } from 'helpers/hooks'
import { useListUserFeaturesQuery } from 'store/dashboardApi'

interface FeatureFlagProps {
  children: any
  flag?: string
  fallback?: React.ReactNode
}

export interface IFeatureFlag {
  flags: string[]
  isLoaded: boolean
}

export const FeatureFlagContext = React.createContext<IFeatureFlag>({
  flags: [],
  isLoaded: false
})

interface FeatureFlagProviderProps {
  children: React.ReactNode
}
export function FeatureFlagProvider({ children }: FeatureFlagProviderProps) {
  const {
    userData: { id }
  } = React.useContext<{ userData: UserData }>(Network.JWTContext as any)
  const { data } = useListUserFeaturesQuery({ userId: id })

  let _data = data?.features?.map((feature) => feature.feature_name)

  if (
    process.env.NODE_ENV === 'development' &&
    process.env.REACT_APP_USER_FLAGS
  ) {
    _data = JSON.parse(process.env.REACT_APP_USER_FLAGS) as string[]
  }

  return (
    <FeatureFlagContext.Provider value={{ flags: _data || [], isLoaded: true }}>
      {children}
    </FeatureFlagContext.Provider>
  )
}

export default function FeatureFlag({
  children,
  flag,
  fallback = null
}: FeatureFlagProps) {
  const isEnabled: boolean | null = useFeatureFlag(flag)

  if (isEnabled === null) return null

  if (isEnabled) return children
  return fallback
}
