import React from 'react'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CloseIcon from '@mui/icons-material/Close'
import { Button, CircularProgress } from '@mui/material'
import { ButtonProps } from '@mediavine/ui/dist/Button'

import TimeoutButton from '../timeoutButton/TimeoutButton'
import { FetchStatus } from 'helpers/types'
import { statusFlags } from 'helpers/constants'

const { ACTIVE, COMPLETED, FAILED } = statusFlags

const SaveButton = ({
  status,
  onClick,
  children,
  invalid
}: SaveButtonProps) => (
  <Button
    variant="contained"
    onClick={() => {
      if (status === ACTIVE) {
        return
      }
      onClick()
    }}
    sx={{ minWidth: '150px', color: '#ffffff', fontSize: '14px' }}
    size="medium"
    disabled={invalid}
  >
    {getPublishStatus(normalizeStatus(status), children)}
  </Button>
)

export default SaveButton

export interface SaveButtonProps extends ButtonProps {
  id?: string
  status: string
  onClick: () => void
  children: string | React.ReactNode
  invalid?: boolean
  inverse?: boolean
}

const getPublishStatus = (status: string, children: React.ReactNode) => {
  switch (status) {
    case ACTIVE:
      return (
        <CircularProgress
          size="18px"
          sx={{ color: '#ffffff' }}
          data-testid="circularProgress"
        />
      )
    case COMPLETED:
      return <TimeoutButton initial={<CheckCircleIcon />} final={children} />
    case FAILED:
      return (
        <TimeoutButton
          initial={
            <CloseIcon
              sx={{ color: (theme) => theme.palette.error.main }}
              data-testid="timeoutButtonFailed"
            />
          }
          final={children}
        />
      )
    default:
      return children
  }
}
/**
 * useRequest and useUpdate have different statuses
 * than Network. This accounts for that.
 */
export function normalizeStatus(status: FetchStatus | string) {
  if (status === 'fetching') return statusFlags.ACTIVE
  if (status === 'fetched') return statusFlags.COMPLETED
  return status
}
