import React, { useEffect, useState } from 'react'
import {
  Text,
  Button,
  Flex,
  Input,
  InputGroup,
  Label,
  Network
} from '@mediavine/ui'
import LoginModal from 'components/modals/LoginModal'
import RequiresRole, { useHasRole } from 'components/RequiresRole'
import { UserContextProps, SiteUser } from 'helpers/context'
import { useRequest, useIsMobile, useUpdate } from 'helpers/hooks'
import { getApi } from 'helpers/data'
import { Form, Field } from 'react-final-form'
import { FormLabelError } from 'components/FormHelpers'
import { AxiosResponse } from 'axios'
import { IData } from 'views/Settings/AdSettings'
import ExternalLink from 'components/ExternalLink'
import { PayeeResponse } from 'helpers/types'
import { MCMChildPublisherResponse } from 'helpers/types'
import { SiteDataContext } from 'state/context/site/siteCtx'

type businessInfoFields = {
  business_domain: string
  business_name: string
}

function validateDomainInformation(values: businessInfoFields) {
  const errors: { [k: string]: string } = {}

  if (!values.business_name) {
    errors.business_name = 'Required'
  }

  if (!values.business_domain) {
    errors.business_domain = 'Required'
  }

  if (
    !/^https?:\/\//.test(values.business_domain) &&
    !/([0-9a-z-]{2,}\.[0-9a-z-]{2,3}\.[0-9a-z-]{2,3}|[0-9a-z-]{2,}\.[0-9a-z-]{2,3})$/i.test(
      values.business_domain
    )
  ) {
    errors.business_domain = 'Please enter a URL or root domain'
  }

  return errors
}

/**
 * Gets payee name for populating "Confirm Payee Name" modal.
 */
async function fetchData(params: {
  id: number
  hasPaymentPermissions: boolean
  hasSettingsPermissions: boolean
}) {
  const api = getApi()
  // If the user doesn't have payment permissions, bail early.
  if (
    !params.hasPaymentPermissions ||
    !params.hasSettingsPermissions ||
    !params.id
  ) {
    return
  }

  const res = (await api.get(`/sites/${params.id}/site_settings/payees`)) as {
    data: PayeeResponse
  }

  const profileRes = await api.get(
    `/sites/${params.id}/site_settings/profile_settings`
  )

  return {
    payee: res.data.site.payee,
    pro_accepted: profileRes.data?.site.pro_accepted
  }
}

export default function BusinessInformationModal() {
  const { id, domain } = React.useContext(SiteDataContext)
  const isMobile = useIsMobile()

  const {
    userData,
    userData: { roles, site_users }
    // @ts-ignore
  } = React.useContext<UserContextProps>(Network.JWTContext)
  const [hasPaymentPermissions] = useHasRole(userData, 'payment', id)
  const [hasSettingsPermissions] = useHasRole(userData, 'ad_settings', id)

  const isUserOwnerOfSite = site_users.some((site_user: SiteUser) => {
    return site_user.site_id === Number(id) && site_user.roles.includes('owner')
  })

  async function fetchMCMChildPubData(params: any) {
    // We don't want to send an ad_settings GET request to check for MCM mcm_child_publisher_id unless we are owners
    // People with ad_settings roles are able to send requests to it but since the results of this
    // call are for MCM purposes we only allow it if the user has the owner role
    if (!isUserOwnerOfSite) return {}

    const api = getApi()
    const { siteId } = params
    const req = await api.get<{ site: IData }>(
      `/sites/${siteId}/site_settings/ad_settings`
    )

    const { mcm_child_publisher_id } = req?.data?.site || {}

    //@ts-ignore
    setMcmChildPubId(mcm_child_publisher_id)

    const req2 = await api.get<
      AxiosResponse<{ mcm_child_publisher: MCMChildPublisherResponse }>
    >(`/mcm_child_publishers/${mcm_child_publisher_id}`)

    //@ts-ignore
    return req2.data.mcm_child_publisher
  }

  // we need to grab the mcm_gam_site_id before we can get business_domain and business_name from
  // /api/v1/mcm_child_publishers/<mcm_child_publisher_id> endpoint
  const { data: mcmChildPubData, fetchStatus: fetchStatusMCMChildPubData } =
    useRequest({
      fetchData: fetchMCMChildPubData,
      initialParams: {
        siteId: id
      }
    })

  const { data, fetchStatus } = useRequest({
    fetchData,
    initialParams: { id, hasPaymentPermissions, hasSettingsPermissions }
  })

  //const { business_name, business_domain } = mcmChildPubData || {}
  const [mcmChildPubId, setMcmChildPubId] = useState<number>(0)
  const [businessInfoUpdated, setBusinessInfoUpdated] = useState(false)
  const [businessInfo, setBusinessInfo] = useState({
    business_name: mcmChildPubData?.business_name,
    business_domain: mcmChildPubData?.business_domain
  })

  // If business_name and business_domain are null AND
  // businessInfo state equals the payee old info show the new modal
  const showBusinessInformationModal =
    !mcmChildPubData?.business_name &&
    !mcmChildPubData?.business_domain &&
    businessInfo?.business_name === data?.payee?.name &&
    businessInfo?.business_domain === domain &&
    !businessInfoUpdated

  const { execute: updateBusinessInfo } = useUpdate(
    async (formData: businessInfoFields) => {
      const api = getApi()
      const { data } = await api.patch(
        `/mcm_child_publishers/${mcmChildPubId}`,
        {
          business_domain: formData.business_domain,
          business_name: formData.business_name
        }
      )
      // make sure to update internal state which controls modal visibility
      setBusinessInfo({
        business_name: data?.mcm_child_publisher?.business_name,
        business_domain: data?.mcm_child_publisher?.business_domain
      })

      // modal flag
      setBusinessInfoUpdated(true)
    },
    {}
  )

  // If we don't have any business name or domain we set them from the payee name and domain fields
  useEffect(() => {
    if (fetchStatus === 'fetched' && fetchStatusMCMChildPubData === 'fetched')
      setBusinessInfo({
        business_name: mcmChildPubData?.business_name || data?.payee?.name,
        business_domain: mcmChildPubData?.business_domain || domain
      })
  }, [data, domain, fetchStatus, fetchStatusMCMChildPubData, mcmChildPubData])

  return (
    <RequiresRole userRole={['owner']}>
      <LoginModal
        heading="Confirm Business Information"
        height={isMobile ? '80vh' : 'auto'}
        open={
          fetchStatus === 'fetched' &&
          fetchStatusMCMChildPubData === 'fetched' &&
          showBusinessInformationModal &&
          !roles.includes('admin')
        }
      >
        <Form
          initialValues={businessInfo}
          onSubmit={updateBusinessInfo}
          validate={validateDomainInformation}
          render={({ handleSubmit, hasValidationErrors }) => (
            <Flex direction="column">
              <Flex padding="sm">
                <Text>
                  Our ad partners require us to publish your business name and
                  business domain in a publicly accessible file. For more
                  information about where this information appears, and why,{' '}
                  <ExternalLink href="https://help.mediavine.com/en/articles/3343481-how-to-update-your-payment-profile-in-mediavine-s-sellers-json-file">
                    see this Help article.
                  </ExternalLink>{' '}
                </Text>
              </Flex>
              <Flex padding="sm">
                <Text>
                  We've pre-filled these fields with your payee name and the
                  domain of your first site with Mediavine. Your business name
                  should be the name of the legal entity that is paid by
                  Mediavine (your business or your name). Your business domain
                  should either be your main site with Mediavine, or your
                  separate business website (if applicable).
                </Text>
              </Flex>
              <Flex padding="sm">
                <Text>
                  Please confirm that the information below is correct, and that
                  you understand it will be made public. You can edit the info
                  below before confirming, but please note that if you want to
                  change it again in the future, you’ll need to contact Support.
                </Text>
              </Flex>
              <Flex>
                <Flex padding="sm" gutter="sm">
                  <Field
                    name="business_name"
                    render={({ input, meta }) => (
                      <InputGroup>
                        <Label htmlFor="business_name">
                          Your Business Name (Site Owner)
                          {meta.error && meta.touched && (
                            <FormLabelError>{meta.error}</FormLabelError>
                          )}
                        </Label>
                        <Input {...input} />
                      </InputGroup>
                    )}
                  />
                </Flex>
                <Flex padding="sm" gutter="sm">
                  <Field
                    name="business_domain"
                    render={({ input, meta }) => (
                      <InputGroup>
                        <Label htmlFor="business_domain">
                          Your Business Domain
                          {meta.error && meta.touched && (
                            <FormLabelError>{meta.error}</FormLabelError>
                          )}
                        </Label>
                        <Input {...input} />
                      </InputGroup>
                    )}
                  />
                </Flex>
              </Flex>
              <Flex justify="center">
                <Button
                  kind="primary"
                  onClick={handleSubmit}
                  disabled={hasValidationErrors}
                >
                  Confirm
                </Button>
              </Flex>
            </Flex>
          )}
        ></Form>
      </LoginModal>
    </RequiresRole>
  )
}
