import React from 'react'
import { Flex, Grid } from '@mediavine/ui'

export default function FormContainer({ children }: any) {
  return (
    <Flex justify="center" align="center">
      <Flex maxWidth="300px">
        <Grid>{children}</Grid>
      </Flex>
    </Flex>
  )
}
