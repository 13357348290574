const typography = {
  fontFamily: 'Proxima Nova',
  h1: {
    fontWeight: 900,
    lineHeight: '60px',
    fontSize: '54px',
    letterSpacing: '1.15px',
    fontFamily: 'Roboto Slab',

    '@media (max-width: 768px)': {
      fontSize: '48px',
      letterSpacing: '1.5px'
    }
  },
  h2: {
    fontWeight: 400,
    lineHeight: '60px',
    fontSize: '44px',
    letterSpacing: '.06px',
    fontFamily: 'Roboto Slab',

    '@media (max-width: 768px)': {
      fontSize: '40px',
      letterSpacing: '.06px',
      lineHeight: '36px'
    }
  },
  h3: {
    fontWeight: 700,
    lineHeight: '40px',
    fontSize: '36px',
    letterSpacing: '1.2px',
    fontFamily: 'Roboto Slab',

    '@media (max-width: 768px)': {
      fontSize: '28px',
      letterSpacing: '1px',
      lineHeight: '32px'
    }
  },
  h4: {
    fontWeight: 700,
    lineHeight: 1,
    fontSize: '28px',
    letterSpacing: '1px',
    fontFamily: 'Roboto Slab',

    '@media (max-width: 768px)': {
      fontSize: '24px',
      letterSpacing: '.8px',
      lineHeight: '32px'
    }
  },
  h5: {
    fontWeight: 700,
    lineHeight: '28px',
    fontSize: '24px',
    letterSpacing: '1px',
    fontFamily: 'Roboto Slab',

    '@media (max-width: 768px)': {
      fontSize: '20px',
      letterSpacing: '.15px',
      lineHeight: '24px'
    }
  },
  h6: {
    fontWeight: 700,
    lineHeight: '28px',
    fontSize: '20px',
    letterSpacing: '.15px',
    fontFamily: 'Roboto Slab',

    '@media (max-width: 768px)': {
      fontSize: '16px',
      letterSpacing: '.15px',
      lineHeight: '24px'
    }
  },
  subtitle1: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '24px'
  },
  subtitle2: {
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '24px'
  },
  body1: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',

    '@media (max-width: 768px)': {
      lineHeight: '22px'
    }
  },
  body2: {
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '24px',

    '@media (max-width: 768px)': {
      lineHeight: '22px'
    }
  },
  caption: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '24px',

    '@media (max-width: 768px)': {
      lineHeight: '22px'
    }
  }
}

export default typography
