import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'
import { apiService } from './dashboard-api'
import { setupListeners } from '@reduxjs/toolkit/query'
import { confirmDialogueSlice } from './confirmDialogue'
import { globalToast } from './globalToast'

export function makeStore() {
  const store = configureStore({
    reducer: {
      confirmDialogue: confirmDialogueSlice.reducer,
      globalToast: globalToast.reducer,
      [apiService.reducerPath]: apiService.reducer
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(apiService.middleware)
  })
  setupListeners(store.dispatch)
  return store
}

export const store = makeStore()

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
