import React from 'react'
import { Button } from '@mui/material'

/**
 * Renders a cancel button to navigate back a page after
 * confirming. Should only be used ONCE on a page.
 * (Otherwise, we need a dynamic id.)
 */
const CancelButton = ({
  children,
  clickHandler
}: {
  children?: React.ReactNode
  clickHandler?: () => void
}) => {

  return (
    <Button variant="outlined" onClick={clickHandler}>
      {children}
    </Button>
  )
}

export default CancelButton
