// @ts-nocheck
/* eslint-disable */
import React from 'react'
import { GlobalThemeContext, PREMIERE_COLOR } from 'helpers/theme'

interface IProps {
  email: string
  user_hash: string
}

declare global {
  interface Window {
    Intercom: (action: 'shutdown' | 'boot' | 'showMessages', args?: any) => void
  }
}

// https://app.intercom.com/a/apps/y4kaxcqu/settings/web
function setup() {
  var APP_ID = process.env.REACT_APP_INTERCOM_KEY
  // prettier-ignore
  ;(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/' + APP_ID;var x=d.getElementsByTagName('script')[0];x?.parentNode?.insertBefore(s, x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
}

export default function IntercomWidget({ email, user_hash }: IProps) {
  // theme
  const { currentTheme } = React.useContext(GlobalThemeContext)
  const isPremiere = currentTheme === 'premiere'
  
  React.useEffect(setup, [])

  React.useEffect(() => {
    const app_id = process.env.REACT_APP_INTERCOM_KEY

    window.Intercom('shutdown')

    window.Intercom('boot', {
      app_id,
      email,
      user_hash,
      action_color: isPremiere ? PREMIERE_COLOR : undefined,
      background_color: isPremiere ? PREMIERE_COLOR : undefined
    })

    return () => window.Intercom('shutdown')
  }, [email, user_hash, isPremiere])

  return null
}

/**
 * Opens intercom widget when called.
 */
export function openWidget() {
  window.Intercom('showMessages')
}
