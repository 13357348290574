import React from 'react'
import styled from 'styled-components'
import { Button, Grid, Text, Flex, H1 } from '@mediavine/ui'
import { ErrorReport } from './ErrorBoundary'

interface IErrorProps {
  buttonText?: string
  message?: string
  onClick?: () => void
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${process.env.PUBLIC_URL}/desert-cactus.svg);
  background-position: bottom;
  background-size: contain;
  background-repeat: no-repeat;
  padding: 25vw;
  text-align: center;
`

export const ErrorNotice = ({ buttonText, message, onClick }: IErrorProps) => {
  return (
    <Wrapper>
      <Grid gutter="md" justify="center">
        <H1>It's a desert out here.</H1>

        <Text>{message}</Text>

        <Flex justify="center">
          <Button
            color="bodyLt"
            onClick={onClick ? onClick : () => window.history.back()}
          >
            {buttonText}
          </Button>
        </Flex>
      </Grid>
    </Wrapper>
  )
}

export function Error404() {
  return (
    <>
      <ErrorNotice
        buttonText="Back to Previous Page"
        message="It looks like this page doesn’t exist!"
      />
      <ErrorReport />
    </>
  )
}

export function Error401() {
  return (
    <ErrorNotice
      buttonText="Back to Previous Page"
      message="You don't have permission to view this page."
    />
  )
}
