import { StatusColor } from 'constants/colors'
import {
  HealthCheckNumericValue,
  HealthCheckSummary,
  HealthCheckStatus,
  Tier
} from './types'
import { PRIVACY_POLICY_HEADINGS } from 'views/HealthCheck/constants'

// https://help.mediavine.com/en/articles/1298121-site-health-checks-overview
export const HEALTH_CHECK_GOALS: {
  [key in HealthCheckNumericValue]: number[]
} = {
  desktop_ads: [0, 1.8, 3, 4],
  sticky_sidebar_ads: [0, 1, 1.5, 2],
  mobile_ads: [0, 3.6, 6.0, 8],
  desktop_viewability: [0, 50, 60, 65],
  mobile_viewability: [0, 45, 55, 60]
}

export const getTealValue = (
  alias: HealthCheckNumericValue | string
): number => {
  // @ts-ignore
  if (!HEALTH_CHECK_GOALS[alias]) return undefined
  // @ts-ignore
  return HEALTH_CHECK_GOALS[alias][3]
}

export const getFillValue = (
  value: number | HealthCheckStatus | null,
  alias: keyof HealthCheckSummary,
  tier: Tier = 'none'
): StatusColor => {
  const primaryColor = tier === 'pro' ? 'primaryLt' : 'primary'
  if (
    typeof value !== 'number' ||
    alias === 'privacy_policy' ||
    alias === 'ads_txt'
  ) {
    // pro tier uses a different primary color, primaryLt
    if (value === 'ok') return primaryColor

    if (value === 'out-of-date') return 'warning'

    if (value === 'missing') {
      // In the context of Privacy policies,
      // 'missing' corresponds to green. Otherwise,
      // it corresponds to red.
      if (alias === 'privacy_policy') return 'success'
      return 'danger'
    }

    if (value === 'unavailable') {
      return 'danger'
    }

    return 'bodyLt'
  }

  if (!value) return 'bodyLt'

  // Get an array of goals – red, yellow, green, teal – for alias.
  // We remove the first value since it's always zero
  const goalsByKey = HEALTH_CHECK_GOALS[alias].slice(1)
  // Find out how many goals have we hit
  const goalColors: StatusColor[] = [
    'danger',
    'warning',
    'success',
    primaryColor
  ]
  const numOfGoalsHit = goalsByKey.filter((v) => value >= v).length
  const finalGoalColor = goalColors[numOfGoalsHit]
  return finalGoalColor
}

export const getScore = (
  value: number | HealthCheckStatus | null,
  alias: keyof HealthCheckSummary
) => {
  if (alias === 'privacy_policy') {
    const fillValue = getFillValue(value, alias)
    return PRIVACY_POLICY_HEADINGS[fillValue]
  }

  if (!value || value === 'unavailable') return 'Unavailable'
  if (value === 'ok') return 'Great!'
  if (value === 'out-of-date') return 'Out of Date'
  if (value === 'missing') return 'Missing'
  return `${value.toFixed(1)}\xa0/\xa0${getTealValue(alias).toFixed(1)}`
}

export const getColor = (value: string, tier?: Tier) => {
  // TODO: use the right color for pro assets
  const finalPrimaryColor =
    tier === 'premiere' ? 'Periwinkle' : tier === 'pro' ? 'Pro' : 'Teal'
  switch (value) {
    case 'primary':
      return finalPrimaryColor
    case 'warning':
      return 'Yellow'
    case 'success':
      return 'Green'
    case 'danger':
      return 'Red'
  }
}
