import React from 'react'
import { Form, Field } from 'react-final-form'
import styled from 'styled-components'
import { ErrorNotice } from './ErrorNotice'
import { Box, Button, Footer, H6, Input } from '@mediavine/ui'
import * as Sentry from '@sentry/browser'

interface IProps {
  children?: React.ReactNode
}
interface IState {
  error: string | null
  status: string
}

const ReportWrap = styled.div`
  position: fixed;
  bottom: 1em;
  right: 1em;
  width: 32ch;
`

export const ErrorReport = () => {
  const [hasReported, setHasReported] = React.useState<boolean>(false)

  if (hasReported)
    return (
      <ReportWrap>
        <Box shadow="box">Thank you!</Box>
      </ReportWrap>
    )

  if (process.env.NODE_ENV !== 'production') return null

  return (
    <ReportWrap>
      <Form
        initialValues={{
          userMessage: ''
        }}
        onSubmit={(extra) => {
          Sentry.captureEvent({ message: 'Manual', extra })
          setHasReported(true)
        }}
      >
        {(props) => (
          <Box shadow="box">
            <H6 as="h2">Did something go wrong?</H6>
            <Field name="userMessage">
              {(props: any) => (
                <Input
                  {...props.input}
                  placeholder="Leave a report&hellip;"
                  autoFocus
                  kind="textarea"
                />
              )}
            </Field>
            <Footer>
              <Button size="sm" onClick={props.handleSubmit}>
                Submit Report
              </Button>
            </Footer>
          </Box>
        )}
      </Form>
    </ReportWrap>
  )
}

class ErrorBoundary extends React.Component<IProps, IState> {
  state = {
    error: null,
    status: 'n/a' // critical, submitted, dismissed
  }

  componentDidCatch(error: any) {
    this.setState({ error, status: 'critical' })
  }

  render() {
    const { status } = this.state
    const { children } = this.props

    if (status !== 'critical') return children

    return (
      <>
        <ErrorNotice
          buttonText="Refresh This Page"
          message="Something went wrong on our end. We’re working to resolve this issue as quickly as possible. Refresh this page or come back later."
          onClick={() => window.location.reload()}
        />
        <ErrorReport />
      </>
    )
  }
}

export default ErrorBoundary
