import { Theme } from '@mui/material/styles'

export default function Select(theme: Theme) {
  return {
    variants: [
      {
        props: { variant: 'standard' }
      }
    ],
    MuiSelect: {
      styleOverrides: {
        root: {
          fontSize: '16px',
          '& .MuiSelect-icon': {
            color: theme.palette.mode === 'dark' ? '#FFF' : '#1E1E1E'
          }
        }
      }
    }
  }
}
