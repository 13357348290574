import React from 'react'
import RequiresRole from './RequiresRole'
import { Grid, Text, Notice } from '@mediavine/ui'
import { GlobalThemeContext } from 'helpers/theme'
import cache from 'helpers/cache'
import RenderIf from './RenderIf'

/**
 * Displays an ADMIN-ONLY msg for premiere managed accounts
 */
export default function PremiereManagedNotice() {
  // theme
  const { currentTheme, isDarkMode } = React.useContext(GlobalThemeContext)
  const isPremiere = currentTheme === 'premiere'
  const isPro = currentTheme === 'pro'

  const isCacheAdmin = cache.get('userData')?.roles.includes('admin')

  // TODO: make sure we use the correct notice color for pro, right now just making sure there is contrast
  const noticeTextColor =
    (isPremiere || isPro) && isDarkMode ? '#444444' : 'auto'

  return (
    <RequiresRole userRole="admin">
      <RenderIf isTrue={isCacheAdmin}>
        <Notice kind="primary" icon="tag">
          <Grid gutter="sm">
            <Text lineHeight="tight" size="sm" color={noticeTextColor}>
              Manage my settings is <Text weight="bold">enabled</Text> for this
              site.
            </Text>
          </Grid>
        </Notice>
      </RenderIf>
    </RequiresRole>
  )
}
