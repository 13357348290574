import { createSlice } from '@reduxjs/toolkit'

const confirmInitialState = {
  title: '',
  content: null,
  dialogueActions: null,
  isOpen: false
}

export const confirmDialogueSlice = createSlice({
  name: 'users',
  initialState: confirmInitialState,
  reducers: {
    setProps: (state, action) => ({
      ...state,
      ...action.payload
    }),
    cancelAndClear: () => confirmInitialState
  }
})

export const { setProps, cancelAndClear } = confirmDialogueSlice.actions
