import { AxiosResponse } from 'axios'

/**
 * This function can intercept responses from axios and scrub
 * personally-identifiable data, like site/video/page titles.
 *
 * This is used by the brand team to be able to take videos and
 * screen captures of the dashboard that aren't tied to a particular
 * site, while using the actual _data_ from any site.
 */
export default function scrubber(res: AxiosResponse<any>) {
  const { data } = res

  // Scrub site title and slug
  if (data.site?.title) data.site.title = "Rosalie's Sweets"
  if (data.site?.slug) data.site.slug = 'rosalies-sweets'

  // Scrub video and page info
  if (data.pages)
    data.pages = data.pages.map((page: any) => ({
      ...page,
      path: getRandomPath()
    }))
  if (data.videos)
    data.videos = data.videos.map((video: any) => ({
      ...video,
      title: getRandomTitle()
    }))

  return { ...res, data }
}

/**
 * Given a range of integers, return a random integer.
 */
function getRandomRange(min: number, max: number) {
  return Math.random() * (max - min) + min
}

/**
 * Given an array, return a random value.
 */
function getRandomFromArray(arr: any[]) {
  return arr[Math.floor(Math.random() * arr.length)]
}

/**
 * Given a length, return a string of asterisks to indicate something is redacted.
 */
function getRedactedPortion(min: number, max: number) {
  return Array.from({ length: getRandomRange(min, max) }).reduce(
    (str) => `${str}*`,
    ''
  )
}

/**
 * Generate a redacted path, ending in a random word.
 */
function getRandomPath() {
  const RANDOM_WORDS = [
    'cupcakes',
    'key-lime-pie',
    'hot-chocolate',
    'tiramisu',
    'silken-tofu',
    'dry-vermouth',
    'cotton-candy',
    'banana-pudding',
    'marscapone'
  ]
  return `${getRedactedPortion(5, 10)}/${getRedactedPortion(
    5,
    10
  )}/${getRandomFromArray(RANDOM_WORDS)}`
}

/**
 * Generate a random title.
 */
function getRandomTitle() {
  const ABC = [
    'A',
    'B',
    'D',
    'E',
    'F',
    'H',
    'I',
    'L',
    'M',
    'N',
    'O',
    'P',
    'R',
    'S',
    'T',
    'W'
  ]
  const wordCount = getRandomRange(4, 7)
  const title = Array.from({ length: wordCount }).map(
    (_) => `${getRandomFromArray(ABC)}${getRedactedPortion(3, 10)} `
  )
  return title
}
