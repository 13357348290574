import React from 'react'
import styled from 'styled-components'
import {
  Box,
  Button,
  Flex,
  Grid,
  H4,
  H5,
  Icon,
  Input,
  Network,
  ScreenReader,
  Text,
  Truncate,
  ScrollWrap,
  Tooltip
} from '@mediavine/ui'
import { Link, NavLink } from 'react-router-dom'
import RequiresRole from 'components/RequiresRole'
import LogoutButton from './LogoutButton'
import SiteFooter from 'components/SiteFooter'
import FavoriteSite from 'components/FavoriteSite'
import {
  UserContextProps,
  UserFavoritesContext,
  FavSitesData,
  NavigationContext
} from 'helpers/context'
import DataPlaceholder from './DataPlaceholder'
import ExternalLink from './ExternalLink'
import { useManageAccount, useLiveOnDate, useIsPubnation } from 'helpers/hooks'
import useIsAdmin from 'helpers/hooks/useIsAdmin'
import { GlobalThemeContext } from 'helpers/theme'
import PremiereLogo from './PremiereLogo'
import FeatureFlag from './FeatureFlag'
import ReverseFeatureFlag from './ReverseFeatureFlag'
import { HideableNavigation } from './NoNavigation'
import { RequiresPremiere } from 'views/Premiere'
import useGoogleAnalytics from 'helpers/useGoogleAnalytics'
import ProLogo from './ProLogo'
import RenderIf from './RenderIf'
import cache from '../helpers/cache'
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt'
import { SiteDataContext } from 'state/context/site/siteCtx'
import { useListSitesQuery } from 'store/dashboardApi'
import { useDebounce } from 'use-debounce'
import GrowIcon from 'components/icons/Grow'
import Uplift from 'components/icons/Uplift'
import ShineLogoLight from 'components/icons/ShineLogoLight'
import ShineLogoDark from 'components/icons/ShineLogoDark'

interface DesktopNavProps {
  children: any
  openByDefault: boolean
}

interface ITopLevelProps {
  children: React.ReactChild
  onExpandUser(): void
  onExpandSettings(): void
  onExpandSwitchSite(): void
}

interface INavLevel {
  onReturn: () => void
}

export type View = 0 | 1 | 2 | 3

export const NONE = 0
export const USER = 1
export const SETTINGS = 2
export const SWITCH_SITES = 3

export const EXPANDED_WIDTH = '236px'
export const SIMPLE_WIDTH = '120px'
export const XL_BREAKPOINT = '1800px'

const BACK_BREAKPOINT = '750px'

const Page = styled.div`
  display: flex;
`
Page.displayName = 'Nav(Page)'

const ViewOuter = styled.div<{ open: boolean }>`
  margin: 0 0 0 ${(props) => (props.open ? '0' : SIMPLE_WIDTH)};
  flex: 1;
  max-width: ${(props) =>
    props.open ? `calc(100% - ${SIMPLE_WIDTH})` : `100%`};
  transition: max-width 0.3s ease 0.5s;
`
ViewOuter.displayName = 'Nav(ViewOuter)'

const MainView = styled.main`
  flex: 1;
  margin: 0 auto;
  max-width: 1200px;
  min-width: 0;
  padding: ${(props) => props.theme.gutter.md};

  @media (min-width: ${XL_BREAKPOINT}) {
    max-width: 1800px;
  }
`
MainView.displayName = 'Nav(View)'

const Navigation = styled.nav<{ open: boolean }>`
  position: sticky;
  top: 0;
  z-index: 1;
  background: ${(props) => props.theme.colors.bodyBg};
  box-shadow: ${(props) => props.theme.shadows.box};
  height: 100vh;
  transition: margin 0.5s ease;
  overflow-y: auto;

  ${(props) =>
    props.open
      ? `
  margin-left: 0;
  `
      : `
  margin-left: -${EXPANDED_WIDTH};
  `}

  a {
    color: inherit;
    text-decoration: none;
  }
`
Navigation.displayName = 'Nav(Navigation)'
const NavigationInner = styled.div`
  position: relative;
`
const SecondaryNavigation = styled.div<{
  open: boolean
  isPro?: boolean
  darkMode?: boolean
}>`
  position: absolute;
  top: 0;
  bottom: 0;
  min-width: ${(props) => (props.open ? '100%' : 0)};
  opacity: ${(props) => (props.open ? 1 : 0)};
  transition: min-width 0.4s ease, opacity 0.2s ease;
  background: ${(props) =>
    props.isPro && props.darkMode ? '#001622' : props.theme.colors.bodyBg};
  > div {
    background: ${(props) =>
      props.isPro && props.darkMode ? '#001622' : props.theme.colors.bodyBg};
    width: 100%;
    white-space: nowrap;
  }
`
SecondaryNavigation.displayName = 'Nav(SecondaryNavigation)'

const Wrap = styled.div<{
  align: 'left' | 'right'
  position?: 'fixed' | 'static' | undefined
  width?: string
  isPro?: boolean
  darkMode?: boolean
}>`
  position: ${(props) => props.position || 'absolute'};
  top: 0;
  width: ${(props) => props.width};
  ${(props) => props.align}: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  z-index: 1;
  background: ${(props) =>
    props.isPro && props.darkMode ? '#001622' : props.theme.colors.bodyBg};

  [data-menu='contracted'] {
    li {
      padding: ${(props) => props.theme.gutter.xs};
      display: flex;
      justify-content: center;
    }

    button {
      width: 100%;
    }

    svg {
      margin: 0 auto;
    }
  }
`
Wrap.displayName = 'Nav(Wrap)'

const SecondaryWrap = styled(Wrap)`
  box-shadow: none;
`
SecondaryWrap.displayName = 'Nav(SecondaryWrap)'

const Header = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${(props) => props.theme.gutter.md};
  border-bottom: 1px solid ${(props) => props.theme.colors.border};

  h1 {
    margin: 0;
    max-width: 67vw;
  }

  @media (max-height: ${BACK_BREAKPOINT}) {
    padding: ${(props) => props.theme.gutter.sm};
  }
`
Header.displayName = 'Nav(Header)'

const Logo = styled.div`
  padding: 0 0 ${(props) => props.theme.gutter.md};
  svg {
    display: block;
    width: 3em;
  }
`
Logo.displayName = 'Nav(Logo)'

const SiteTitle = styled.h1<{ children: string }>`
  // If a title contains more than 12 characters with no spaces, adjust font size
  font-size: ${(props) => (/[^\s]{12}/.test(props.children) ? '20px' : '30px')};
  text-align: center;
  font-family: 'Roboto Slab', serif;
  word-break: break-word;
  line-height: 1.1;
`
SiteTitle.displayName = 'Nav(SiteTitle)'

const Main = styled.section`
  flex: 1;
  padding: ${(props) => props.theme.gutter.md};
  border-bottom: 1px solid ${(props) => props.theme.colors.border};

  @media (max-height: ${BACK_BREAKPOINT}) {
    padding: ${(props) => props.theme.gutter.md}
      ${(props) => props.theme.gutter.md} 0;
  }
`
Main.displayName = 'Nav(Main)'

const LinkList = styled.ul<{ simple?: boolean }>`
  list-style: none;
  padding: 0;
  li {
    button,
    a {
      display: flex;
      align-items: center;
      padding: 1em;
      color: ${(p) => p.theme.colors.body};
    }
    a.active > svg:nth-child(1) {
      color: ${(p) => p.theme.colors.primaryDk};
    }
    svg {
      width: 24px;
      margin-right: 0.25em;
    }
    svg + span {
      margin-left: 0.7em;
    }
  }

  li#grow {
    svg {
      width: initial;
      margin-right: 0.25em;
    }
  }

  // Badges
  span + span {
    padding-top: 0.25em;
    padding-bottom: 0.25em;
    margin-left: 0.5em;
  }
`
LinkList.displayName = 'Nav(LinkList)'

const FormLinkListItem = styled.li<any>`
  display: flex;
  align-items: center;

  svg {
    margin: ${(props) => (props.simple ? '0' : '0 .7em 0 0')};
  }
  div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
`
FormLinkListItem.displayName = 'Nav(FormLinkListItem)'

const Footer = styled.section<{
  isPro?: boolean
  darkMode?: boolean
}>`
  display: flex;
  background: ${(props) =>
    props.isPro && props.darkMode ? '#001622' : props.theme.colors.bodyBg};
`
Footer.displayName = 'Nav(Footer)'

const Profile = styled.div`
  flex: 1;
  padding: ${(props) => {
    return `${props.theme.gutter.lg} ${props.theme.gutter.sm}`
  }};
  border-left: 1px solid ${(props) => props.theme.colors.border};
`
Profile.displayName = 'Nav(Profile)'

const Toggle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
  padding: ${(props) => props.theme.gutter.lg};

  @media (max-height: ${BACK_BREAKPOINT}) {
    padding: ${(props) => props.theme.gutter.sm};
  }
`
Toggle.displayName = 'Nav(Toggle)'

const Avatar = styled.img`
  vertical-align: middle;
  width: 58px;
  height: 58px;
  margin: 0 0 0.5em;
  object-fit: contain;
  border-radius: 50%;
  cursor: pointer;
`
Avatar.displayName = 'Nav(Avatar)'

const AvatarWrapper = styled.div`
  display: flex;
  justify-content: center;
  border: none;
  background: none;
  border-bottom: 1px solid ${(props) => props.theme.colors.border};
  padding: ${(props) => props.theme.gutter.lg};
  width: 100%;

  @media (max-height: ${BACK_BREAKPOINT}) {
    padding: ${(props) => props.theme.gutter.sm};
  }
`
AvatarWrapper.displayName = 'Nav(AvatarWrapper)'

function SwitchSitesCheck({ children }: any) {
  const {
    userData: { roles, site_users }
    // @ts-ignore
  } = React.useContext<UserContextProps>(Network.JWTContext)
  const isAdmin = roles.includes('admin')
  const hasMultipleSites = site_users.length > 1

  return isAdmin || hasMultipleSites ? children : null
}

function GrowMenuLink() {
  const { id } = React.useContext(SiteDataContext)

  return (
    <div>
      <NavLink to={`/sites/${id}/settings/grow`}>
        <GrowIcon />
        <span data-test="grow">Grow</span>
      </NavLink>
    </div>
  )
}

export function TopLevel({
  children,
  onExpandUser,
  onExpandSettings,
  onExpandSwitchSite
}: ITopLevelProps) {
  const {
    userData: { name }
    // @ts-ignore
  } = React.useContext<UserContextProps>(Network.JWTContext)
  const { title, id, domain, givenNotice, deactivated } =
    React.useContext(SiteDataContext)

  // theme
  const { currentTheme } = React.useContext(GlobalThemeContext)
  const isPremiere = currentTheme === 'premiere'
  const isPro = currentTheme === 'pro'
  const liveOnDate = useLiveOnDate()
  const isAdmin = useIsAdmin()
  const isAdminWithAdminBar = useIsAdmin(true)
  const hidePaymentSection = deactivated && !isAdmin
  const adminLink = process.env.REACT_APP_API?.split('/api/')[0]
  const { isDarkMode } = React.useContext(GlobalThemeContext)
  const isCacheAdmin = cache.get('userData')?.roles.includes('admin')
  const isPubnation = useIsPubnation()
  const isNotPubnation = !isPubnation || isAdminWithAdminBar

  const getLogo = () => {
    if (isPremiere) return <PremiereLogo />
    if (isPro) return <ProLogo />

    return <Icon kind="mediavine" />
  }

  // Flag used to determine if we want to render the health check nav link
  const renderHealthCheckNavLink =
    (liveOnDate || false) && (!givenNotice || isAdminWithAdminBar)

  return (
    <>
      <Header>
        <Logo>{getLogo()}</Logo>
        <ExternalLink href={`http://${domain}`}>
          <SiteTitle>{title}</SiteTitle>
        </ExternalLink>
      </Header>
      <Main>
        <LinkList data-menu="expanded">
          <RequiresRole userRole={['reporting', 'post_termination_new_owner']}>
            <li>
              <NavLink to={`/sites/${id ? `${id}/` : ''}`}>
                <Icon kind="home" color="primary" />
                <span data-test="dashboard">Dashboard</span>
              </NavLink>
            </li>
          </RequiresRole>
          {id > 0 && (
            <>
              <RenderIf isTrue={renderHealthCheckNavLink}>
                <RequiresRole
                  userRole={['reporting', 'post_termination_new_owner']}
                >
                  <li>
                    <NavLink to={`/sites/${id}/health-check/desktop-ads`}>
                      <Icon kind="healthCheck" />
                      <span data-test="health-check">Health Check</span>
                    </NavLink>
                  </li>
                </RequiresRole>
              </RenderIf>
              <RequiresRole userRole="video">
                <li>
                  <NavLink to={`/sites/${id}/videos`}>
                    <Icon kind="video" />
                    <span data-test="videos">Videos</span>
                  </NavLink>
                </li>
                <FeatureFlag flag="playlists">
                  <li>
                    <NavLink to={`/sites/${id}/playlists`}>
                      <Icon kind="list" />
                      <span data-test="playlists">Playlists</span>
                    </NavLink>
                  </li>
                </FeatureFlag>
              </RequiresRole>
              <RenderIf isTrue={!hidePaymentSection}>
                <RequiresRole userRole="payment">
                  <li>
                    <NavLink to={`/sites/${id}/payments`}>
                      <Icon kind="payments" />
                      <span data-test="payments">Payments</span>
                    </NavLink>
                  </li>
                </RequiresRole>
              </RenderIf>
              <RequiresRole userRole="ad_settings">
                <li>
                  <Button kind="link" onClick={onExpandSettings} size="md">
                    <Icon kind="settings" />
                    <span data-test="settings">Settings</span>
                    <Icon
                      kind="arrowRight"
                      size="sm"
                      color="bodyLt"
                      style={{ width: '15px', padding: '4px', margin: '3px' }}
                    />
                  </Button>
                </li>
              </RequiresRole>
              <RequiresRole userRole={['owner']}>
                <li>
                  <GrowMenuLink />
                </li>
              </RequiresRole>
              <FeatureFlag flag="uplift_page">
                <RequiresRole userRole={['owner']}>
                  <li>
                    <NavLink to={`/sites/${id}/settings/uplift`}>
                      <Uplift />
                      <span data-test="influencer">Uplift</span>
                    </NavLink>
                  </li>
                </RequiresRole>
              </FeatureFlag>
              <FeatureFlag flag="enable_shine">
                <RenderIf isTrue={isNotPubnation}>
                  <li>
                    <NavLink
                      to={`/sites/${id}/settings/ad-settings/#psa-options-header`}
                    >
                      {isDarkMode ? <ShineLogoDark /> : <ShineLogoLight />}
                      <span data-test="shine">Shine</span>
                    </NavLink>
                  </li>
                </RenderIf>
              </FeatureFlag>
            </>
          )}
          <SwitchSitesCheck>
            <li>
              <Button kind="link" onClick={onExpandSwitchSite} size="md">
                <Icon kind="external" />
                <span>Switch Sites</span>
                <Icon
                  kind="arrowRight"
                  size="sm"
                  color="bodyLt"
                  style={{ width: '15px', padding: '4px', margin: '3px' }}
                />
              </Button>
            </li>
          </SwitchSitesCheck>
          <RenderIf isTrue={isCacheAdmin}>
            <RequiresRole userRole="admin">
              {/* TODO: Remove this Admin link when the admin bar goes live. */}
              <li>
                <ExternalLink
                  href={
                    id
                      ? `${adminLink}/admin/site/${id}/edit`
                      : `${adminLink}/admin`
                  }
                >
                  <Icon kind="account" />
                  <span>Admin</span>
                </ExternalLink>
              </li>
            </RequiresRole>
          </RenderIf>
        </LinkList>
      </Main>
      <Footer isPro={isPro} darkMode={isDarkMode}>
        <Toggle>{children}</Toggle>
        <Profile>
          <Grid gutter="sm" justify="center">
            <Network.JWTConsumer>
              {({ userData }: any) => <Avatar src={userData.gravatar} />}
            </Network.JWTConsumer>
            <Button kind="link" onClick={onExpandUser} data-test="arrow-button">
              <Text color="body" size="sm">
                <Truncate>{name && name.split(' ')[0]}</Truncate>
                <Icon
                  kind="arrowRight"
                  size="sm"
                  color="bodyLt"
                  style={{ padding: '0 4px', margin: '-2px 2px' }}
                />
              </Text>
            </Button>
          </Grid>
        </Profile>
      </Footer>
    </>
  )
}

export function UserLevel({ onReturn }: INavLevel) {
  const { id } = React.useContext(SiteDataContext)
  return (
    <>
      <Flex justify="flex-start">
        <Button kind="icon" onClick={onReturn}>
          <ScreenReader>Back</ScreenReader>
          <Icon kind="arrowLeft" size="6px" color="bodyLt" />
        </Button>
        <H4>Your Info</H4>
      </Flex>
      <LinkList>
        {id > 0 && (
          <li>
            <NavLink to={`/sites/${id}/profile`}>
              <Icon kind="profile" />
              <span>Profile</span>
            </NavLink>
          </li>
        )}
        <li>
          <NavLink to={`/account`}>
            <Icon kind="account" />
            <span>Account</span>
          </NavLink>
        </li>
        <li>
          <ExternalLink href="https://help.mediavine.com/">
            <Icon kind="help" />
            <span>Help</span>
          </ExternalLink>
        </li>
      </LinkList>

      <LogoutButton>Sign out</LogoutButton>
    </>
  )
}

export function SettingsLevel({ onReturn }: INavLevel) {
  // Get a site's "manage_account" value. If it's true,
  // we should hide the links for ad settings and optouts.
  // We should render a link
  const manageMyAccount = useManageAccount()
  const { id, title, domain, offering_id } = React.useContext(SiteDataContext)
  // Don't run for admins
  const isCacheAdmin = cache.get('userData')?.roles.includes('admin')
  const { isDarkMode } = React.useContext(GlobalThemeContext)
  const isPubnation = useIsPubnation()
  const isAdminWithAdminBar = useIsAdmin(true)
  const isNotPubnation = !isPubnation || isAdminWithAdminBar

  return (
    <>
      <H4 style={{ whiteSpace: 'normal' }}>
        <ExternalLink href={`http://${domain}`}>{title}</ExternalLink>
      </H4>
      <LinkList>
        <RequiresPremiere>
          <li>
            <NavLink to={`/sites/${id}/settings/premiere`}>
              <PremiereLogo />
              <span>Premiere</span>
            </NavLink>
          </li>
        </RequiresPremiere>
        {!manageMyAccount && (
          <>
            <RequiresRole userRole="ad_settings">
              <li>
                <NavLink to={`/sites/${id}/settings/ad-settings`}>
                  <Icon kind="adSettings" />
                  <span data-test="ad-settings">Ad Settings</span>
                </NavLink>
              </li>
            </RequiresRole>
            <RequiresRole userRole="ad_settings">
              <li>
                <NavLink to={`/sites/${id}/settings/in-content-ads`}>
                  <Icon kind="inContentAds" />
                  <span data-test="in-content-ads">In-Content Ads</span>
                </NavLink>
              </li>
            </RequiresRole>
          </>
        )}
        {manageMyAccount && (
          <RequiresRole userRole="ad_settings">
            <li>
              <NavLink to={`/sites/${id}/settings/psas`}>
                <Icon kind="heartHome" />
                <span>PSAs</span>
              </NavLink>
            </li>
          </RequiresRole>
        )}
        <FeatureFlag flag="enable_shine">
          <RenderIf isTrue={isNotPubnation}>
            <li>
              <NavLink
                to={`/sites/${id}/settings/ad-settings/#psa-options-header`}
              >
                {isDarkMode ? <ShineLogoDark /> : <ShineLogoLight />}
                <span data-test="shine">Shine</span>
              </NavLink>
            </li>
          </RenderIf>
        </FeatureFlag>
        <RequiresRole userRole="ad_settings">
          <li>
            <NavLink to={`/sites/${id}/settings/video`}>
              <Icon kind="video" />
              <span data-test="video">Video</span>
            </NavLink>
          </li>
        </RequiresRole>
        <RequiresRole userRole="owner">
          <FeatureFlag flag="ga4_settings_page">
            <li>
              <NavLink to={`/sites/${id}/settings/google-analytics-4`}>
                <SignalCellularAltIcon color="primary" />
                <span>GA4</span>
              </NavLink>
            </li>
          </FeatureFlag>
        </RequiresRole>
        <RequiresRole userRole="ad_settings">
          <li>
            <NavLink to={`/sites/${id}/settings/privacy`}>
              <Icon kind="eye" />
              <span data-test="privacy">Privacy</span>
            </NavLink>
          </li>
        </RequiresRole>
        <RenderIf isTrue={isCacheAdmin || offering_id !== 2}>
          {!manageMyAccount && (
            <RequiresRole userRole="ad_settings">
              <li>
                <NavLink to={`/sites/${id}/settings/opt-out`}>
                  <Icon kind="optOut" />
                  <span data-test="opt-out">Opt-Out</span>
                </NavLink>
              </li>
            </RequiresRole>
          )}
        </RenderIf>
        <li>
          <NavLink to={`/sites/${id}/settings/profile`}>
            <Icon kind="profile" />
            <span data-test="profile">Profile</span>
          </NavLink>
        </li>
        <ReverseFeatureFlag flag="uplift_page">
          <RequiresRole userRole={['owner']}>
            <li>
              <NavLink to={`/sites/${id}/settings/influencer`}>
                <Icon kind="influencer" />
                <span data-test="influencer">Influencer</span>
              </NavLink>
            </li>
          </RequiresRole>
        </ReverseFeatureFlag>
        <FeatureFlag flag="uplift_page">
          <RequiresRole userRole={['owner']}>
            <li>
              <NavLink to={`/sites/${id}/settings/uplift`}>
                <Uplift />
                <span data-test="influencer">Uplift</span>
              </NavLink>
            </li>
          </RequiresRole>
        </FeatureFlag>
        <RequiresRole userRole="ad_settings">
          <li>
            <NavLink to={`/sites/${id}/settings/disable-ads`}>
              <Icon kind="danger" />
              <span data-test="disable-ads">Disable Ads</span>
            </NavLink>
          </li>
        </RequiresRole>
        <RequiresRole userRole="ad_settings">
          <li>
            <NavLink to={`/sites/${id}/settings/ad-setup`}>
              <Icon kind="setUp" />
              <span data-test="ad-setup">Ad Setup</span>
            </NavLink>
          </li>
        </RequiresRole>
        <RequiresRole userRole="owner">
          <li>
            <NavLink to={`/sites/${id}/settings/users`}>
              <Icon kind="users" />
              <span data-test="users">Users</span>
            </NavLink>
          </li>
        </RequiresRole>
        <li id="grow">
          <GrowMenuLink />
        </li>
        <FeatureFlag flag="beta_center">
          <li>
            <NavLink to={`/sites/${id}/settings/beta-center`}>
              <Icon kind="beta" />
              <span data-test="beta-center">Beta Center</span>
            </NavLink>
          </li>
        </FeatureFlag>
      </LinkList>
    </>
  )
}

export function SwitchSitesLevel({ onReturn }: { onReturn(): void }) {
  const [query, setQuery] = React.useState('')
  const [debouncedQuery] = useDebounce(query, 300)
  const { data, isLoading } = useListSitesQuery({
    page: 1,
    query: debouncedQuery
  })
  const sites = data?.sites || []

  const googleAnalytics = useGoogleAnalytics()

  const { favoritesIdList } =
    React.useContext<FavSitesData>(UserFavoritesContext)
  const { data: favSitesResp } = useListSitesQuery(
    { siteIds: favoritesIdList },
    { skip: favoritesIdList.length === 0 }
  )
  const favSites =
    favoritesIdList.length === 0 || !favSitesResp ? [] : favSitesResp.sites

  const favSitesSortedAndFiltered = favSites
    .slice()
    .sort(function (a, b) {
      return favoritesIdList?.indexOf(a.id) - favoritesIdList?.indexOf(b.id)
    })
    .filter(({ title }) => title?.toLowerCase().includes(query.toLowerCase()))

  const makeList = () => {
    return [
      ...favSitesSortedAndFiltered,
      ...sites.filter(
        ({ id }) => !favSitesSortedAndFiltered?.some((fav) => fav.id === id)
      )
    ]
  }

  const list = makeList()

  return (
    <Grid gutter="sm">
      <Flex justify="flex-start">
        <H5 as="h4">Switch Sites</H5>
        <NavLink to="/sites" style={{ flexBasis: '30px' }}>
          <Text color="primary" weight="normal">
            All
          </Text>
        </NavLink>
      </Flex>
      <Input
        value={query}
        onChange={(query) => setQuery(query)}
        placeholder="Search for a site..."
      />
      <ScrollWrap maxHeight="70vh">
        {list.length === 0 && !isLoading && (
          <Box bg="none">
            <Text>No matching sites</Text>
          </Box>
        )}
        {list.length > 0 && (
          <LinkList>
            {list.map((site, i: number) => (
              <FormLinkListItem key={site.id}>
                <div>
                  <Flex width="100%" justify="flex-start">
                    <FavoriteSite id={site.id} />
                    <Link
                      to={`/sites/${site.id}`}
                      onClick={() => {
                        onReturn()
                        googleAnalytics.reset()
                      }}
                      title={site.title || ''}
                    >
                      <Truncate maxWidth="15ch">{site.title}</Truncate>
                    </Link>
                  </Flex>
                </div>
              </FormLinkListItem>
            ))}
          </LinkList>
        )}
        {isLoading && <DataPlaceholder />}
      </ScrollWrap>
    </Grid>
  )
}

function TopLevelSimple({
  children,
  onExpandSwitchSite,
  onExpandUser,
  onExpandSettings
}: ITopLevelProps) {
  const {
    userData: { name }
    // @ts-ignore
  } = React.useContext<UserContextProps>(Network.JWTContext)
  const { id, givenNotice, deactivated } = React.useContext(SiteDataContext)
  const liveOnDate = useLiveOnDate()
  const isAdmin = useIsAdmin()
  const hidePaymentSection = deactivated && !isAdmin

  // theme
  const { currentTheme } = React.useContext(GlobalThemeContext)
  const isPremiere = currentTheme === 'premiere'
  const isPro = currentTheme === 'pro'

  const { isDarkMode } = React.useContext(GlobalThemeContext)

  const renderHealthCheckNavLink =
    (liveOnDate || false) && (!givenNotice || isAdmin)

  const getLogo = () => {
    if (isPremiere)
      return (
        <div style={{ width: '24px' }}>
          <PremiereLogo />
        </div>
      )
    if (isPro)
      return (
        <div style={{ width: '18px' }}>
          <ProLogo />
        </div>
      )

    return <Icon kind="mediavine" size="lg" />
  }
  return (
    <Wrap align="left" position="fixed" isPro={isPro} darkMode={isDarkMode}>
      <Header style={{ textAlign: 'center' }}>
        <Link to={`/sites/${id || ''}`}>{getLogo()}</Link>
      </Header>
      <Main>
        <LinkList data-menu="contracted">
          <RequiresRole userRole={['reporting', 'post_termination_new_owner']}>
            <li>
              <NavLink to={`/sites/${id || ''}`}>
                <Icon kind="home" />
                <ScreenReader>Home</ScreenReader>
              </NavLink>
            </li>
          </RequiresRole>
          {id > 0 && (
            <>
              <RenderIf isTrue={renderHealthCheckNavLink}>
                <RequiresRole
                  userRole={['reporting', 'post_termination_new_owner']}
                >
                  <li>
                    <Tooltip
                      id="health-check"
                      align="right"
                      message="Health Check"
                    >
                      <NavLink to={`/sites/${id}/health-check/desktop-ads`}>
                        <Icon kind="healthCheck" />
                        <ScreenReader>Health Check</ScreenReader>
                      </NavLink>
                    </Tooltip>
                  </li>
                </RequiresRole>
              </RenderIf>
              <RequiresRole userRole="video">
                <li>
                  <Tooltip id="video" align="right" message="Videos">
                    <NavLink to={`/sites/${id}/videos`}>
                      <Icon kind="video" />
                      <ScreenReader>Videos</ScreenReader>
                    </NavLink>
                  </Tooltip>
                </li>
              </RequiresRole>
              <FeatureFlag flag="playlists">
                <li>
                  <Tooltip id="playlists" align="right" message="Playlists">
                    <NavLink to={`/sites/${id}/playlists`}>
                      <Icon kind="list" />
                      <ScreenReader>Playlists</ScreenReader>
                    </NavLink>
                  </Tooltip>
                </li>
              </FeatureFlag>
              <RenderIf isTrue={!hidePaymentSection}>
                <RequiresRole userRole="payment">
                  <li>
                    <Tooltip id="payments" align="right" message="Payments">
                      <NavLink to={`/sites/${id}/payments`}>
                        <Icon kind="payments" />
                        <ScreenReader>Payment</ScreenReader>
                      </NavLink>
                    </Tooltip>
                  </li>
                </RequiresRole>
              </RenderIf>
              <RequiresRole userRole="ad_settings">
                <li>
                  <Tooltip id="settings" align="right" message="Settings">
                    <Button kind="link" onClick={onExpandSettings} size="md">
                      <Icon kind="settings" />
                      <ScreenReader>Expand Settings</ScreenReader>
                    </Button>
                  </Tooltip>
                </li>
              </RequiresRole>
              <li>
                <NavLink to={`/sites/${id}/settings/grow`}>
                  <Icon kind="grow" color="primary" />
                </NavLink>
              </li>
            </>
          )}
          <SwitchSitesCheck>
            <li>
              <Tooltip id="switch" align="right" message="Switch Sites">
                <Button kind="link" onClick={onExpandSwitchSite} size="md">
                  <Icon kind="external" />
                  <ScreenReader>Switch Sites</ScreenReader>
                </Button>
              </Tooltip>
            </li>
          </SwitchSitesCheck>
        </LinkList>
      </Main>
      <Tooltip
        id="greeting-tip"
        message={`Hi, ${name && name.split(' ')[0]}!`}
        align="right"
      >
        <AvatarWrapper id="greeting" onClick={onExpandUser}>
          <Network.JWTConsumer>
            {({ userData }: any) => <Avatar src={userData.gravatar} />}
          </Network.JWTConsumer>
        </AvatarWrapper>
      </Tooltip>
      <Toggle>{children}</Toggle>
    </Wrap>
  )
}

const SecondaryLevel = ({
  children,
  onReturn
}: {
  children: React.ReactNode
  onReturn: () => void
}) => (
  <SecondaryWrap align="right">
    <Main>{children}</Main>
    <Toggle>
      <Button
        kind="link"
        color="bodyLt"
        onClick={onReturn}
        size="md"
        style={{ fontWeight: 'normal', margin: '24px 40px' }}
      >
        <Icon kind="arrowLeft" color="bodyLt" size=".5em" />
        {' Back'}
      </Button>
    </Toggle>
  </SecondaryWrap>
)

function DesktopNavigation({ children, openByDefault }: DesktopNavProps) {
  const [open, setOpen] = React.useState(openByDefault)
  const [level, setLevel] = React.useState<View>(NONE)

  const handleExpand = (navItem: View) => {
    setLevel(navItem)
    setOpen(true)
  }

  // theme
  const { currentTheme } = React.useContext(GlobalThemeContext)
  const isPro = currentTheme === 'pro'

  const { isDarkMode } = React.useContext(GlobalThemeContext)

  return (
    <Page>
      <HideableNavigation>
        <TopLevelSimple
          onExpandUser={() => handleExpand(USER)}
          onExpandSettings={() => handleExpand(SETTINGS)}
          onExpandSwitchSite={() => handleExpand(SWITCH_SITES)}
        >
          <Button
            kind="icon"
            onClick={() => setOpen(!open)}
            data-test="double-arrow-button1"
          >
            <Icon kind="doubleCaretRight" color="bodyLt" />
          </Button>
        </TopLevelSimple>
        <Navigation open={open}>
          <NavigationInner>
            <Wrap
              align="right"
              position="static"
              width={EXPANDED_WIDTH}
              isPro={isPro}
              darkMode={isDarkMode}
            >
              <TopLevel
                key={level}
                onExpandUser={() => setLevel(USER)}
                onExpandSettings={() => setLevel(SETTINGS)}
                onExpandSwitchSite={() => setLevel(SWITCH_SITES)}
              >
                <Button
                  kind="icon"
                  onClick={() => setOpen(!open)}
                  data-test="double-arrow-button2"
                >
                  <Icon kind="doubleCaretLeft" color="bodyLt" />
                </Button>
              </TopLevel>
            </Wrap>

            {/* Secondary Menus */}
            <SecondaryNavigation
              open={level > 0}
              isPro={isPro}
              darkMode={isDarkMode}
            >
              {level > 0 && (
                <SecondaryLevel onReturn={() => setLevel(NONE)}>
                  {level === USER && (
                    <UserLevel onReturn={() => setLevel(NONE)} />
                  )}
                  {level === SETTINGS && (
                    <SettingsLevel onReturn={() => setLevel(NONE)} />
                  )}
                  {level === SWITCH_SITES && (
                    <SwitchSitesLevel onReturn={() => setLevel(NONE)} />
                  )}
                </SecondaryLevel>
              )}
            </SecondaryNavigation>
          </NavigationInner>
        </Navigation>
      </HideableNavigation>
      <ViewOuter open={open}>
        <NavigationContext.Provider value={{ openDrawer: open }}>
          <MainView>
            {children}
            <SiteFooter />
          </MainView>
        </NavigationContext.Provider>
      </ViewOuter>
    </Page>
  )
}

export default DesktopNavigation
