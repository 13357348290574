import React from 'react'
import { GlobalThemeContext } from 'helpers/theme'
import { UserData } from 'helpers/context'
import { Network } from '@mediavine/ui'

interface RequiresPremiereProps {
  children: any
  fallback?: React.ReactNode
}

/**
 * Returns a fallback unless we're in a site with premiere status.
 */
function RequiresPremiere({
  children,
  fallback = null
}: RequiresPremiereProps) {

  // theme
  const { currentTheme } = React.useContext(GlobalThemeContext)
  const isPremiere = currentTheme === 'premiere'

  if (isPremiere) return children
  return fallback
}

/**
 * Returns a fallback unless a user has at least one premiere-eligible site.
 * Useful for things like enrollment view.
 */
function RequiresPremiereEligibility({
  children,
  fallback = null
}: RequiresPremiereProps) {
  // @ts-ignore
  const { userData } = React.useContext(Network.JWTContext) as {
    userData: UserData
  }
  const isUserEligible = userData.premiere_sites_invited.length > 0
  if (isUserEligible) return children
  return fallback
}

export { RequiresPremiere, RequiresPremiereEligibility }
