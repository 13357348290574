import React, { useContext } from 'react'
import {
  StyledEngineProvider,
  createTheme,
  ThemeOptions,
  ThemeProvider as MUIThemeProvider
} from '@mui/material/styles'
import palette from './palette'
import typography from './typography'
import componentOverrides from 'components/overrides'
import { GlobalThemeContext } from 'helpers/theme'

type ThemeProviderProps = {
  children: React.ReactNode
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    copy: true
  }
}

declare module '@mui/material/styles' {
  interface Palette {
    stack: Palette['primary']
  }
  interface PaletteOptions {
    stack: PaletteOptions['primary']
  }

  interface Palette {
    tealTipHeader: Palette['primary']
  }
}

export default function ThemeProvider({ children }: ThemeProviderProps) {
  const { currentTheme, isDarkMode } = useContext(GlobalThemeContext)

  const themeOptions: ThemeOptions = {
    palette: {
      mode: isDarkMode ? 'dark' : 'light',
      ...palette(currentTheme, isDarkMode)
    },
    typography: {
      ...typography,
      button: {
        textTransform: 'none'
      }
    }
  }

  const theme = createTheme(themeOptions)
  theme.components = componentOverrides(theme)

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>{children}</MUIThemeProvider>
    </StyledEngineProvider>
  )
}
