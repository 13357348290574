import React from 'react'
import styled from 'styled-components'

const Base = styled.svg`
  width: 100%;
  height: auto;
`

export default function ProLogo() {
  return (
    <Base xmlns="http://www.w3.org/2000/svg" viewBox="0 0 49 67">
      <g clipPath="url(#a)" fill="#3E74C1">
        <path d="M26.43 0v42.361c11.778-.286 22.07-9.583 22.07-21.178C48.5 9.59 38.208.287 26.43 0Z" />
        <path d="M.526 21.569C1.084 9.734 10.719.287 22.626 0v45.3H22.6C22.049 57.14 12.408 66.584.506 66.87V21.569h.026-.005Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" transform="translate(.5)" d="M0 0h48v66.87H0z" />
        </clipPath>
      </defs>
    </Base>
  )
}
