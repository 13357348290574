import React, { useEffect, useState } from 'react'
import { Form, Field } from 'react-final-form'
import { Link } from 'react-router-dom'
import { UserData } from 'helpers/context'
import {
  Box,
  Button,
  CacheSourceControl,
  Input,
  Password,
  Flex
} from '@mediavine/ui'
import FormContainer from './FormContainer'
import AuthLayout from '../../components/AuthLayout'
import { useUpdate } from 'helpers/hooks'
import Cookies from 'js-cookie'
import { getApi } from 'helpers/data'

export interface ILoginResponse {
  data: {
    data: {
      token: string
      user: UserData
    }
  }
}
export interface ILoginData {
  data: {
    token: string
    user: UserData
    twoFactorRequired: boolean
  }
}
const normalizeLoginResponse = (res: ILoginResponse): ILoginData => {
  const token = res.data.data.token
  const user = res.data.data.user
  const twoFactorRequired = Boolean(res.data.data.user.twilio_verify_enabled)
  Cookies.set('jwt', token, { domain: 'mediavine.com' })
  return {
    data: {
      token,
      user,
      twoFactorRequired
    }
  }
}

const normalizeRequest = (data: any) => {
  const trimmedEmail = data.email.trim()
  const req = {
    email: trimmedEmail,
    password: data.password
  }

  return req
}

interface ILoginFormProps {
  onLogin(data: ILoginData): void
}

export default function LoginForm({ onLogin }: ILoginFormProps) {
  const api = getApi()
  const { execute, updateError } = useUpdate(async (data: any) => {
    const res = await api.post(`/users/sign_in`, normalizeRequest(data))
    return normalizeLoginResponse(res)
  }, {})
  const [error, setError] = useState<boolean>()

  useEffect(() => {
    setError(true)
  }, [updateError])

  return (
    <Form
      initialValues={{ email: '', password: '' }}
      onSubmit={async (data) => {
        const res = await execute(data as any)
        if (res) {
          onLogin(res)
        }
      }}
    >
      {(props) => (
        <form onSubmit={props.handleSubmit}>
          <AuthLayout error={updateError} showError={error}>
            <FormContainer>
              <Box padding="sm" shadow="box" bg="bodyBg">
                <Field name="email">
                  {(props) => (
                    <Input
                      {...props.input}
                      {...{ 'data-private': '' }}
                      placeholder="email"
                      kind="inline"
                      onKeyDown={() => {
                        setError(false)
                      }}
                    />
                  )}
                </Field>
              </Box>
              <Box padding="sm" shadow="box" bg="bodyBg">
                <Field name="password">
                  {(props) => (
                    <Password
                      {...props.input}
                      {...{ 'data-private': '' }}
                      placeholder="password"
                      // @ts-ignore
                      onKeyDown={() => {
                        setError(false)
                      }}
                    />
                  )}
                </Field>
              </Box>
              <Button kind="primary" type="submit">
                Log In
              </Button>
              <CacheSourceControl />
              <Flex justify="center">
                <Link to="/users/password/new">Forgot Password?</Link>
              </Flex>
            </FormContainer>
          </AuthLayout>
        </form>
      )}
    </Form>
  )
}
