import React from 'react'
import { GlobalThemeContext } from 'helpers/theme'

interface MediavineLogoProps {
  width?: string
  icon?: boolean
}
export default function MediavineLogo({
  width = '159px',
  icon = false
}: MediavineLogoProps) {
  const { isDarkMode } = React.useContext(GlobalThemeContext)

  if (icon) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="36px" height="24px">
        <path
          fill="#41A4A9"
          d="M33.013 1.768c-.91.271-2.621.942-3.287 2.284-.627 1.262-.213 2.997.164 4 .91-.27 2.625-.941 3.291-2.285.667-1.342.167-3.11-.168-3.999zm-3.996 8.05l-.26-.514c-.068-.137-1.676-3.385-.409-5.936C29.614.817 33.172.133 33.323.105L33.89 0l.26.515c.069.137 1.676 3.384.409 5.936-1.265 2.55-4.824 3.235-4.975 3.263l-.567.105zM7.68 7.986h.01v15.743a7.877 7.877 0 0 1-7.68-7.497H0V.488a7.878 7.878 0 0 1 7.68 7.498zm9.036 0h.009v15.743a7.877 7.877 0 0 1-7.68-7.497h-.01V.488c4.139.1 7.488 3.383 7.68 7.498zm9.035 0h.01v15.743a7.877 7.877 0 0 1-7.68-7.497h-.01V.488a7.878 7.878 0 0 1 7.68 7.498z"
        />
      </svg>
    )
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 733.8 80.7"
      width={width}
    >
      <path
        fill={isDarkMode ? '#fff' : '#243C4A'}
        d="M192.1 30.5l-16.8 47.4h-10.1l-16.8-47.4v47.4h-22.7V2.8h31.4l13.2 38.3 13.2-38.3h31.4v75.1h-22.7V30.5zM225.9 2.8h57v18.9h-34.2v8.8h33.4v18.9h-33.4V59h34.2v18.9h-57V2.8zM292.8 2.8h33.5c23.6 0 41.2 13.5 41.2 37.5s-17.5 37.6-41 37.6h-33.7V2.8zm33.6 55.3c11.4 0 18.1-8.3 18.1-17.8 0-10.1-5.9-17.7-18-17.7h-10.9v35.5h10.8zM374.6 2.8h22.7v75.1h-22.7V2.8zM458.5 68.2h-28.8l-2.9 9.7h-24.7l27.7-75.1h28.6L486 77.9h-24.7l-2.8-9.7zm-23-18.9h17.1L444 22.8l-8.5 26.5z"
      />
      <g>
        <path
          fill={isDarkMode ? '#fff' : '#41A4A9'}
          d="M468.4 2.8h25.8l16.2 50.5 16.2-50.5h25.8l-27.7 75.1h-28.6L468.4 2.8zM557.3 2.8H580v75.1h-22.7V2.8zM613.7 37.6v40.3H591V2.8h23.4l26.1 37.8V2.8h22.7v75.1h-21.8l-27.7-40.3zM674.3 2.8h57v18.9H697v8.8h33.4v18.9H697V59h34.2v18.9h-57V2.8z"
        />
      </g>
      <g>
        <path
          fill={isDarkMode ? '#fff' : '#41A4A9'}
          d="M96.4 34.5l-.8-1.7c-.2-.4-5.4-10.9-1.3-19.2 4.1-8.2 15.6-10.5 16.1-10.5l1.8-.3.8 1.7c.2.4 5.4 10.9 1.3 19.2-4.1 8.2-15.6 10.5-16.1 10.5l-1.8.3zm12.9-26c-2.9.9-8.5 3-10.6 7.4-2 4.1-.7 9.7.5 12.9 2.9-.9 8.5-3 10.6-7.4 2.2-4.3.6-10-.5-12.9zM27.4 27C26.8 13.8 16 3.1 2.6 2.8v50.9C3.3 67 14.1 77.6 27.5 77.9L27.4 27c.1 0 0 0 0 0zM56.6 27C56 13.8 45.2 3.1 31.8 2.8v50.9c.7 13.3 11.5 23.9 24.9 24.2L56.6 27zM85.8 27C85.2 13.8 74.4 3.1 61 2.8v50.9c.7 13.3 11.5 23.9 24.8 24.2V27z"
        />
      </g>
    </svg>
  )
}
