import React from 'react'
import { createGlobalStyle } from 'styled-components'
import {
  ThemeBase,
  theme,
  makeDarkTheme,
  darkThemeCssGlobals
} from '@mediavine/ui'
import cache from 'helpers/cache'
import SVGGlobals from 'components/SVGGlobals'
import { darken } from 'polished'
import Lato from '../fonts/Lato-Regular.ttf'
import LatoBold from '../fonts/Lato-Bold.ttf'
import LatoBlack from '../fonts/Lato-Black.ttf'

const baseTheme = theme({
  colors: {
    navBg: '#fff',
    earnings: 'white',
    chartPrimary: '#243C4A',
    chartPrimaryLt: '#5ca2a8',
    chartSecondary: '#837A9F',
    chartSecondaryLt: '#ddd',
    chartTertiary: '#D79894',
    primaryMd: '#A3DBDF',
    background: '#FAFAFA'
  },
  skeletonGradient:
    'linear-gradient(90deg, rgba(50,50,50,.04), rgba(50,50,50,.06), rgba(50,50,50,.04))'
})

const darkTheme = makeDarkTheme(baseTheme, {
  colors: {
    bodyMd: '#eee',
    navBg: '#181818',
    earnings: '#5ca2a8',
    infoLt: darken(0.2, baseTheme.colors.info),
    primaryLt: darken(0.1, baseTheme.colors.primary),
    warningLt: darken(0.1, baseTheme.colors.warning),
    dangerLt: darken(0.1, baseTheme.colors.danger),
    successLt: darken(0.1, baseTheme.colors.success),
    chartSecondaryLt: darken(0.1, baseTheme.colors.chartSecondary),
    toggle: '#fff',
    background: '#1E1E1E'
  },
  skeletonGradient:
    'linear-gradient(90deg, rgba(0,0,0,.90), rgba(0,0,0,.92), rgba(0,0,0,.90))'
})

// const enterpriseTheme = theme({
//   colors: {
//     primary: '#5A71AD',
//     secondary: '#E8EFF6'
//   }
// })

// const

const Global = createGlobalStyle<{ darkMode: boolean }>`
${
  process.env.REACT_APP_BE_QUIET &&
  `
body > iframe {
  display: none;
}
`
}

*, *:before, *:after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Proxima Nova';
  background: #FAFAFA;
}

button {
  font-family: 'Proxima Nova';
}

a {
  color: ${(props) => props.theme.colors.primary};

  &:hover {
    color: ${(props) => props.theme.colors['primaryDk']};
  }
}

// Prevent double rules from showing on reports page
hr + hr,
hr + div:empty,
hr + div:empty + hr {
  display: none;
}

[data-placement] {
  z-index: 5;
}

${(props) =>
  props.darkMode &&
  `
${darkThemeCssGlobals}

// TODO: Pretty much all of these should be in darkThemeCssGlobals.
// Address this in compost.

input[readonly] {
  background: #333;
}

// Windows uses the body color for select elements,
// but not the body _background_ color, so we need
// to prevent white-on-white text display.
option {
  background: ${props.theme.colors.bodyBg};
}

[data-reach-listbox-popover] {
  background: #333!important;
}

text,
span {
  fill: #fff;
}

`}

// Override borders of dropdowns
[data-dropdown],
[data-dropdown] > div {
  border: none!important;
}

// TODO: Add to UI
input:last-child {
  width: 100%;
}

// Grow fonts
@font-face {
  font-family: 'Lato';
  src: local('Lato'), local('Lato-Regular'), url(${Lato}) format('truetype');
  font-weight: 400;
  font-display: 'swap';
  font-style: 'normal'
}

@font-face {
  font-family: 'Lato';
  src: local('Lato'), local('Lato-Bold'), url(${LatoBold}) format('truetype');
  font-weight: 700;
  font-display: 'swap';
  font-style: 'normal'
}

@font-face {
  font-family: 'Lato';
  src: local('Lato'), local('Lato-Black'), url(${LatoBlack}) format('truetype');
  font-weight: 800 900;
  font-display: 'swap';
  font-style: 'normal'
}
`

export type ThemeOptions = 'default' | 'premiere' | 'pro'

export interface GlobalThemeContextProps {
  currentTheme: ThemeOptions | undefined
  setCurrentTheme(value: ThemeOptions): void
  isDarkMode: boolean
  toggleDarkMode: () => void
}

export const GlobalThemeContext = React.createContext<GlobalThemeContextProps>({
  currentTheme: 'default',
  setCurrentTheme: (_value: ThemeOptions) => {},
  isDarkMode: false,
  toggleDarkMode: () => {}
})

interface GlobalThemeProviderProps {
  children: React.ReactNode
}

export const GlobalThemeProvider = ({ children }: GlobalThemeProviderProps) => {
  const [currentTheme, setCurrentTheme] =
    React.useState<ThemeOptions>('default')

  const [isDarkMode, setIsDarkMode] = React.useState<boolean>(
    cache.get('isDarkMode') === undefined
      ? !window.matchMedia('(prefers-color-scheme: light)').matches
      : cache.get('isDarkMode')
  )

  const toggleDarkMode = () => {
    const style = document.createElement('style')
    style.type = 'text/css'
    style.innerText = `
    * {
      transition: all .2s ease;
    }
    `
    document.head.appendChild(style)
    setTimeout(() => style.remove(), 500)
    setIsDarkMode((m) => !m)
    cache.set({ isDarkMode: !isDarkMode })
  }

  // @ts-ignore
  const theme = isDarkMode ? darkTheme : baseTheme

  const premiereTheme = {
    ...theme,
    colors: {
      ...theme.colors,
      primary: PREMIERE_COLOR,
      primaryDk: darken(0.1, PREMIERE_COLOR),
      primaryLt: isDarkMode ? darken(0.1, PREMIERE_COLOR) : '#EAEEF9',
      toggleOnBg: isDarkMode ? darkTheme.colors.toggleOnBg : PREMIERE_COLOR
    }
  }

  const proTheme = {
    ...theme,
    colors: {
      ...theme.colors,
      primary: PRO_COLOR,
      primaryDk: '#004990',
      primaryLt: isDarkMode ? darken(0.1, PRO_COLOR) : '#F5F9FF',
      toggleOnBg: isDarkMode ? darkTheme.colors.toggleOnBg : PRO_COLOR
    }
  }

  const finalTheme = () => {
    if (currentTheme === 'premiere') return premiereTheme
    if (currentTheme === 'pro') return proTheme

    return theme
  }

  return (
    <ThemeBase theme={finalTheme()}>
      <GlobalThemeContext.Provider
        value={{ currentTheme, setCurrentTheme, isDarkMode, toggleDarkMode }}
      >
        <SVGGlobals />
        <Global darkMode={isDarkMode} />
        {children}
      </GlobalThemeContext.Provider>
    </ThemeBase>
  )
}

const PREMIERE_COLOR = '#5A71AD'
const ENTERPRISE_COLOR = '#2561A5'
const PRO_COLOR = '#3E74C1'

export { baseTheme, darkTheme, PREMIERE_COLOR, ENTERPRISE_COLOR }
