import * as React from 'react'

interface WidgetContextValue {
  callbackUrl: string | null
  redirectUri: string | null
  siteId: string | null
  sessionId: string | null
  isSubscribeRequired: boolean
}

export const WidgetContext = React.createContext<WidgetContextValue>({
  callbackUrl: null,
  redirectUri: null,
  siteId: null,
  sessionId: null,
  isSubscribeRequired: false
})

export function useCallbackUrl() {
  return React.useContext(WidgetContext).callbackUrl
}

export function useSiteId() {
  return React.useContext(WidgetContext).siteId
}

export function useWidgetSessionId() {
  return React.useContext(WidgetContext).sessionId
}

export function useIsSubscribeRequired() {
  return React.useContext(WidgetContext).isSubscribeRequired
}

export function useRedirectUri() {
  return React.useContext(WidgetContext).redirectUri
}
