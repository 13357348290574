import { Theme } from '@mui/material/styles'

export default function Divider(theme: Theme) {
  return {
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(0, 0, 0, 0.12)'
        }
      }
    }
  }
}
