import React, { useContext } from 'react'
import { UserData } from 'helpers/context'
import { Network } from '@mediavine/ui'
import { AdminBarContext } from 'helpers/context'

/**
 * Returns true if the user is an admin.
 * useWithAdminBar allows this hook to check against
 * admin bar faux simulated data instead of real data
 */
export default function useIsAdmin(useWithAdminBar: boolean = false) {
  // @ts-ignore
  const { userData } = React.useContext(Network.JWTContext) as {
    userData: UserData
  }
  const { isAdminBarOpen, simulatedUserData } = useContext(AdminBarContext)

  // If admin bar is being used and faux data is valid we check against
  // this admin bar faux data
  if (
    isAdminBarOpen &&
    useWithAdminBar &&
    simulatedUserData &&
    Object.keys(simulatedUserData).length > 0
  ) {
    return (simulatedUserData as UserData).roles.includes('admin')
  }

  // Otherwise we simply do a normal admin role check
  return userData?.roles?.includes('admin')
}
