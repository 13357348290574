import React from 'react'
import styled from 'styled-components'
import { GlobalThemeContext } from 'helpers/theme'

const Base = styled.svg`
  width: 100%;
  height: auto;
`
const LargeBase = styled.svg`
  width: 159px;
  height: auto;
`

export default function PremiereLogo() {
  return (
    <Base xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
      <path
        d="M566.07 419.73c147.79-143.88 428.64-96.32 428.64-96.32s41.23 280.84-106.57 424.72-428.64 96.32-428.64 96.32-41.22-280.84 106.57-424.72z"
        fill="#5a71ad"
      />
      <path
        d="M72.68 766.25C-30.68 657.37 6.51 453.41 6.51 453.41s204.77-27.43 308.12 81.45S380.8 847.7 380.8 847.7s-204.76 27.44-308.12-81.45z"
        fill="#8697c1"
      />
      <path
        d="M267.95 347.26c-13.3-101.62 89.17-199.24 89.17-199.24s124.03 67.13 137.33 168.75C507.76 418.38 405.29 516 405.29 516s-124.04-67.13-137.34-168.74z"
        fill="#b3bdd8"
      />
    </Base>
  )
}

export function LargePremiereLogo() {
  const { isDarkMode } = React.useContext(GlobalThemeContext)
  return (
    <LargeBase
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      viewBox="0 0 792 125"
    >
      <path
        d="M78.76 64.48c20.56-20.02 59.64-13.4 59.64-13.4s5.74 39.07-14.83 59.09-59.64 13.4-59.64 13.4-5.74-39.07 14.83-59.09z"
        fill="#5970ab"
      />
      <path
        d="M10.11 112.69C-4.27 97.54.91 69.17.91 69.17S29.4 65.35 43.78 80.5s9.21 43.52 9.21 43.52-28.5 3.82-42.88-11.33z"
        fill="#8496c0"
      />
      <path
        d="M37.28 54.4c-1.85-14.14 12.41-27.72 12.41-27.72s17.26 9.34 19.11 23.48c1.85 14.14-12.41 27.72-12.41 27.72S39.13 68.54 37.28 54.4z"
        fill="#b2bcd7"
      />
      <g>
        <path
          fill={isDarkMode ? '#fff' : '#243c4a'}
          d="M168.59 39.54h39.8c18.5 0 28.57 12.5 28.57 27.43 0 14.8-10.21 27.17-28.57 27.17H186.7v30.49h-18.11V39.54zm37.38 15.56H186.7v23.47h19.26c7.14 0 12.5-4.59 12.5-11.74.01-7.01-5.35-11.73-12.49-11.73zM281.58 94.13h-13.27v30.49H250.2V39.54H290c17.73 0 28.57 11.61 28.57 27.43 0 15.05-9.44 23.09-18.62 25.26l19.13 32.4h-20.79l-16.71-30.5zm5.74-39.03h-19.01v23.47h19.01c7.27 0 12.76-4.59 12.76-11.74 0-7.14-5.48-11.73-12.76-11.73zM335.77 39.54h60.21V55.1h-42.1v18.5h41.2v15.56h-41.2v19.77h42.1v15.69h-60.21V39.54zM489.01 63.39l-23.98 61.23h-7.91l-23.98-61.23v61.23h-18.11V39.54h25.39l20.66 53.07 20.66-53.07h25.51v85.08h-18.24V63.39zM528.19 39.54h18.11v85.08h-18.11V39.54zM566.97 39.54h60.21V55.1h-42.1v18.5h41.2v15.56h-41.2v19.77h42.1v15.69h-60.21V39.54zM677.6 94.13h-13.27v30.49h-18.11V39.54h39.8c17.73 0 28.57 11.61 28.57 27.43 0 15.05-9.44 23.09-18.62 25.26l19.13 32.4h-20.79l-16.71-30.5zm5.74-39.03h-19.01v23.47h19.01c7.27 0 12.76-4.59 12.76-11.74 0-7.14-5.48-11.73-12.76-11.73zM731.79 39.54H792V55.1h-42.1v18.5h41.2v15.56h-41.2v19.77H792v15.69h-60.21V39.54z"
        />
      </g>
      <g>
        <path
          fill={isDarkMode ? '#fff' : '#243c4a'}
          d="M193.22 10.65L187 28.23h-3.76l-6.22-17.58v17.58h-8.43V.38h11.65l4.88 14.2L190 .38h11.65v27.85h-8.43V10.65zM206.17.38h21.13v7.01h-12.69v3.26h12.4v7.01h-12.4v3.55h12.69v7.01h-21.13V.38zM231.06.38h12.44c8.77 0 15.28 5.01 15.28 13.9s-6.51 13.95-15.24 13.95h-12.48V.38zm12.44 20.5c4.22 0 6.72-3.09 6.72-6.6 0-3.76-2.17-6.55-6.68-6.55h-4.05v13.15h4.01zM261.79.38h8.43v27.85h-8.43V.38zM292.65 24.64h-10.69l-1.09 3.59h-9.14L282 .38h10.6l10.27 27.85h-9.14l-1.08-3.59zm-8.52-7.02h6.35l-3.17-9.81-3.18 9.81zM297.62.38h9.56l6.01 18.75L319.2.38h9.56L318.5 28.23h-10.6L297.62.38zM330.27.38h8.43v27.85h-8.43V.38zM351.65 13.28v14.95h-8.43V.38h8.68l9.69 14.03V.38h8.43v27.85h-8.1l-10.27-14.95zM374.54.38h21.13v7.01h-12.69v3.26h12.4v7.01h-12.4v3.55h12.69v7.01h-21.13V.38z"
        />
      </g>
    </LargeBase>
  )
}
