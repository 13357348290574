import React from 'react'
import { useIsMobile } from 'helpers/hooks'
import { Box, Grid, H4 } from '@mediavine/ui'
import { Modal as MuiModal, ModalProps } from '@mui/material'
import FeatureFlag from 'components/FeatureFlag'
import RenderIf from 'components/RenderIf'

interface MVModalProps extends Omit<ModalProps, 'children'> {
  id?: string
  flag?: string
  heading?: string
  children: React.ReactNode | ((cb: () => void) => React.ReactNode)
  width?: any
  height?: any
  open: boolean
  useHeader?: boolean
  labelledBy?: string
}

export default function LoginModal({
  id,
  flag,
  heading,
  children,
  width,
  labelledBy,
  height = 'auto',
  open = false,
  useHeader = true,
  sx,
  ...props
}: MVModalProps) {
  const isMobile = useIsMobile()
  const [dismissed, setDismissed] = React.useState(false)

  if (dismissed || !open) return null

  return (
    <FeatureFlag flag={flag}>
      <MuiModal
        id={`modal-${flag}-${id}`}
        open={true}
        onClose={() => {}}
        aria-labelledby={labelledBy ? labelledBy : `modal-${flag}-header`}
        sx={{
          width,
          height,
          ...sx
        }}
        {...props}
      >
        <>
          <RenderIf isTrue={useHeader}>
            <Box
              bg="bodyBg"
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: isMobile ? undefined : 'auto',
                height: isMobile ? '80vh' : 'auto',
                overflowY: isMobile ? 'scroll' : 'auto'
              }}
            >
              <Grid>
                <H4 id={`modal-${flag}-header`} as="div">
                  {heading}
                </H4>
                {typeof children === 'function'
                  ? children(() => setDismissed(true))
                  : children}
              </Grid>
            </Box>
          </RenderIf>
          <RenderIf isTrue={!useHeader}>
            <Box
              style={{
                position: 'absolute',
                top: '50vh',
                left: '50vw',
                transform: 'translate(-50%, -50%)'
              }}
            >
              {typeof children === 'function'
                ? children(() => setDismissed(true))
                : children}
            </Box>
          </RenderIf>
        </>
      </MuiModal>
    </FeatureFlag>
  )
}
