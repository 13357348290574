import React, { Suspense } from 'react'
import styled from 'styled-components'
import {
  Redirect,
  Switch,
  Route,
  useRouteMatch,
  useLocation
} from 'react-router-dom'
import {
  Network,
  Notice,
  Grid,
  Text,
  Box,
  Spinner,
  CompoundBox
} from '@mediavine/ui'
import Header from 'components/Header'
import MobileNavigation from 'components/MobileNavigation'
import DesktopNavigation from 'components/DesktopNavigation'

import OnRouteChange from 'components/OnRouteChange'
import { Error404, Error401 } from 'components/ErrorNotice'
import {
  UserData,
  SiteUser,
  UserFavoritesProvider,
  HealthChecksDataContext,
  AdminBarProvider,
  useUserData
} from 'helpers/context'
import { GoogleAnalyticsProvider } from 'helpers/useGoogleAnalytics'
import { useIsMobile, useRequest } from 'helpers/hooks'
import useIsAdmin from 'helpers/hooks/useIsAdmin'

import NewPassword from 'views/Authorization/NewPassword'
import EditPassword from 'views/Authorization/EditPassword'
import AcceptInvitation from 'views/Authorization/AcceptInvitation'
import LoginForm, { ILoginData } from 'views/Authorization/Login'
import { getApi, getJwt } from 'helpers/data'
import { SkeletonItem } from 'components/LoadingSkeleton'
import LogoutButton from 'components/LogoutButton'
import PremiereManagedNotice from 'components/PremiereManagedNotice'
import { Role } from 'views/Settings/Users'
import { useSendTrackingEvent } from 'helpers/trackingEvents'
import FeatureFlag from 'components/FeatureFlag'
import AdminBar from 'components/adminBar'
import { createClient, Provider as GrowProvider } from 'urql'
import { HealthCheckPath } from 'helpers/types'
import RenderIf from 'components/RenderIf'
import { SiteDataContext } from 'state/context/site/siteCtx'
import { LoginModals } from 'components/LoginModals'

const client = createClient({
  url: `${process.env.REACT_APP_GROW_ME_API}/graphql`,
  fetchOptions: () => {
    const token = getJwt()
    return {
      headers: { authorization: token ? `Bearer ${token}` : '' }
    }
  }
})

const SettingsFallback = () => (
  <CompoundBox shadow="box" border="none">
    <Box bg="bodyBg" style={{ height: '67v' }}>
      <SkeletonItem />
    </Box>
  </CompoundBox>
)

// General
const Account = React.lazy(() => import('views/Account'))
const Sites = React.lazy(() => import('views/Sites'))
const Home = React.lazy(() => import('views/Home'))
const Profile = React.lazy(() => import('views/Profile'))
const Payments = React.lazy(() => import('views/Payments'))

// Videos
const Videos = React.lazy(() => import('views/Videos'))
const VideoCollection = React.lazy(() => import('views/Videos/VideoCollection'))
const VideoAddNew = React.lazy(() => import('views/Videos/AddNew'))
const VideoEdit = React.lazy(() => import('views/Videos/Edit'))
const VideoPreview = React.lazy(() => import('views/Videos/VideoPreview'))
const Playlists = React.lazy(() => import('views/Playlists'))
const PlaylistCollection = React.lazy(
  () => import('views/Playlists/PlaylistCollection')
)
const EditPlaylist = React.lazy(() => import('views/Playlists/EditPlaylist'))
const NewPlaylist = React.lazy(() => import('views/Playlists/NewPlaylist'))

// Premiere
const PremiereEnrollment = React.lazy(
  () => import('views/Premiere/premiereEnrollment/PremiereEnrollmentPage')
)
const PremiereSettings = React.lazy(
  () => import('views/Premiere/PremiereSettings')
)

// Health Checks
const HealthCheck = React.lazy(() => import('views/HealthCheck'))

// Settings
const Settings = React.lazy(() => import('views/Settings'))
const AdSettings = React.lazy(() => import('views/Settings/AdSettings'))
const AdSetup = React.lazy(() => import('views/Settings/AdSetup'))
const DisableAds = React.lazy(() => import('views/Settings/DisableAds'))
const InContentAds = React.lazy(() => import('views/Settings/InContentAds'))
const Uplift = React.lazy(() => import('views/Settings/uplift'))
const OptOutSettings = React.lazy(() => import('views/Settings/optOut/OptOut'))
const PrivacySettings = React.lazy(
  () => import('views/Settings/privacyPolicy/PrivacyPolicy')
)
const ProfileSettings = React.lazy(
  () => import('views/Settings/profile/Profile')
)
const UserSettings = React.lazy(() => import('views/Settings/user/Users'))
const VideoSettings = React.lazy(() => import('views/Settings/Video'))
const PSASettings = React.lazy(() => import('views/Settings/PSAs'))
const Grow = React.lazy(() => import('views/Settings/Grow'))
const BetaCenter = React.lazy(() => import('views/Settings/BetaCenter'))
const GA4 = React.lazy(() => import('views/Settings/ga4/GA4'))

interface SiteProps {
  siteId: string
  siteStatus: string
  children: React.ReactNode
}

/**
 * Wraps the view for a site. Checks for permissions, and the
 * existence of the site. Renders top-level notices for deactivated
 * sites, and whether we're connected to staging or not. Renders
 * a notice when site data is being fetched.
 */
function Site({ siteId, children, siteStatus }: SiteProps) {
  const {
    // @ts-ignore
    userData: { roles: originalRoles, site_users: originalSiteUsers }
  } =
    // @ts-ignore
    React.useContext(Network.JWTContext) as { userData: UserData }
  const { killswitch, deactivated, premiere_manage_account, id } =
    React.useContext(SiteDataContext)

  let roles: Role[] = originalRoles
  let site_users: SiteUser[] = originalSiteUsers

  if (process.env.NODE_ENV === 'development') {
    // If we have the react app user role env var defined, fetch the contents and replace the original roles
    if (process.env.REACT_APP_USER_ROLE)
      roles = JSON.parse(process.env.REACT_APP_USER_ROLE)
    // If we have the react app site user role env var defined, fetch the contents and replace the original site_users roles
    if (process.env.REACT_APP_SITE_USER_ROLE)
      site_users = [
        {
          roles: JSON.parse(process.env.REACT_APP_SITE_USER_ROLE),
          site_id: Number(siteId)
        }
      ]
  }

  // If user doesn't have permission for this site, display a 401.
  const doesUserHavePermission =
    roles.includes('admin') ||
    site_users.some((site_user) => site_user.site_id === Number(siteId))

  if (!doesUserHavePermission) {
    return <Error401 />
  }

  if (siteStatus === 'not found') {
    return <Error404 />
  }
  return (
    <Grid>
      {roles.includes('admin') && premiere_manage_account && (
        <PremiereManagedNotice />
      )}
      {roles.includes('admin') && killswitch && (
        <Notice kind="warning">This site has been killswitched.</Notice>
      )}
      {deactivated && (
        <Notice kind="warning">This site has been deactivated.</Notice>
      )}

      {process.env.REACT_APP_API?.includes('staging') && (
        <Notice kind="warning">
          You are connected to the staging API. Changes will not affect
          production sites.
        </Notice>
      )}
      {siteStatus === 'active' && (
        <SwitchingSitesWrap>
          <Box>
            <Grid justify="center" gutter="sm">
              <Spinner />
              <Text>Loading Site</Text>
            </Grid>
          </Box>
        </SwitchingSitesWrap>
      )}
      <RenderIf isTrue={Boolean(id)}>
        <LoginModals />
      </RenderIf>
      {children}
    </Grid>
  )
}

const SwitchingSitesWrap = styled.div`
  position: fixed;
  z-index: 99;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: ${(props) => props.theme.shadows.box};
`

/** If the window is larger than 1100px, we have enough room to open the side menu by default. */
const MENU_OPEN_BREAKPOINT = 1100

/**
 * Renders either the mobile or desktop navigation, based on a breakpoint.
 */
function Navigation(props: any) {
  const isMobile = useIsMobile()
  const NavigationComponent = isMobile ? MobileNavigation : DesktopNavigation

  return (
    <NavigationComponent
      {...props}
      openByDefault={window.innerWidth > MENU_OPEN_BREAKPOINT}
    />
  )
}

interface PublicRoutesProps {
  onLogin(data: ILoginData): void
}

/**
 * These routes don't require authentication.
 */
export function PublicRoutes(props: PublicRoutesProps) {
  const { onLogin } = props
  return (
    <Switch>
      <Route path="/users/invitation/accept">
        <AcceptInvitation onLogin={onLogin} />
        <Header routeTitle="Authorization" />
      </Route>
      <Route path="/users/password/new">
        <NewPassword />
        <Header routeTitle="Authorization" />
      </Route>
      <Route path="/users/password/edit">
        <EditPassword />
        <Header routeTitle="Authorization" />
      </Route>
      <Route>
        <LoginForm onLogin={onLogin} />
        <Header routeTitle="Authorization" />
      </Route>
    </Switch>
  )
}

type RoutesProps = {
  defaultSite: number | undefined
}

/**
 * These routes require authentication. We manage the site data outside
 * of the scope of the Site component itself, since we need it for the navigation.
 */
export default function DashboardRouter({ defaultSite }: RoutesProps) {
  const match = useRouteMatch<{ siteId: string }>(`/sites/:siteId`)
  const location = useLocation()
  const [siteStatus, setSiteStatus] = React.useState('idle')
  const { id, givenNotice, features, setActiveSite, siteContextError } =
    React.useContext(SiteDataContext)
  const siteId = match?.params?.siteId
  const siteIdNum = siteId ? Number(siteId) : null
  const userData = useUserData()

  const [isAdminViewToggled, setIsAdminViewToggled] =
    React.useState<boolean>(false)
  const isAdmin = useIsAdmin()
  const isAdminWithAdminBar = useIsAdmin(true)

  const sendTrackingEvent = useSendTrackingEvent(siteId)

  // Handlers for custom URL params
  React.useEffect(() => {
    /**
     * The Tipalti iframe will redirect a user back to the application after
     * completion. In this case, it passes back a URL param so that way the UI
     * can send an API request to the backend alerting it that payment
     * information was successfully updated. We execute this in a hook in this
     * component because it's the first place we have an auth context.
     */
    if (location.search?.includes('?tipalti_completed=true')) {
      const api = getApi()
      api
        .patch(`/sites/${siteId}/site_settings/payees/confirm`)
        .then(() => console.log('payee confirmed!'))
    }
  }, [siteId, location.search])

  const fetchHealthCheckData = async () => {
    if (!siteIdNum) return

    // Each site has specific roles for specific users
    const siteUserReportingPermit = userData.site_users.some(
      (site_user: SiteUser) => {
        return (
          (site_user.site_id === siteIdNum &&
            site_user.roles.includes('owner')) ||
          (site_user.site_id === siteIdNum &&
            site_user.roles.includes('reporting')) ||
          (site_user.site_id === siteIdNum &&
            site_user.roles.includes('post_termination_new_owner'))
        )
      }
    )

    // In addition to site user specific roles we check for roles attached to the user
    // object. If none of these are included in the roles object then we return and don't
    // make any requests
    if (!siteUserReportingPermit && !userData.roles.includes('admin')) return

    const api = getApi('v2')
    const res = await api.get(`/sites/${siteId}/reports/health_checks`)
    return res.data
  }

  // We fetch health check data for context provider
  const { data, fetchStatus } = useRequest({
    fetchData: fetchHealthCheckData,
    initialParams: { siteId: siteId }
  })

  // Track if the URL has changed to a different site and update our Site context
  React.useEffect(() => {
    if (siteIdNum) {
      setActiveSite(siteIdNum)
      setSiteStatus('active')
    }
  }, [siteIdNum, setActiveSite, setSiteStatus])

  // If the ID updates then we know the request for the new site has finished
  React.useEffect(() => {
    if (typeof id === 'number' ? siteId : siteIdNum) {
      if (siteStatus !== 'completed') {
        setSiteStatus('completed')
        sendTrackingEvent({ name: 'render_route' })
      }
    }
  }, [id, siteId, siteIdNum, fetchStatus, siteStatus, sendTrackingEvent])

  // If the request failed, we assume they are trying to access a site that doesn't exist or they don't have access to
  React.useEffect(() => {
    if (siteContextError !== null) {
      setSiteStatus('not found')
      sendTrackingEvent({ name: 'render_route_fail' })
    }
  }, [siteContextError, setSiteStatus, sendTrackingEvent])

  return (
    <GoogleAnalyticsProvider>
      <UserFavoritesProvider userData={userData}>
        <AdminBarProvider>
          <HealthChecksDataContext.Provider value={data && data}>
            <GrowProvider value={client}>
              <Navigation>
                <Switch>
                  <Route exact path="/premiere">
                    <Suspense fallback={null}>
                      <PremiereEnrollment />
                    </Suspense>
                  </Route>

                  <Route exact path="/users/invitation/accept">
                    <RequiresLogout />
                  </Route>

                  <Route exact path="/users/password/new">
                    <RequiresLogout />
                  </Route>

                  <Route exact path="/users/password/edit">
                    <RequiresLogout />
                  </Route>

                  <Route exact path="/account">
                    <Suspense fallback={null}>
                      <Account />
                    </Suspense>
                    <Header routeTitle="Account" />
                  </Route>

                  <Route
                    path="/sites/:siteId"
                    render={(props) => (
                      <Site
                        siteId={props.match.params.siteId}
                        siteStatus={siteStatus}
                      >
                        <Switch>
                          <Route exact path="/sites/:siteId">
                            <Suspense fallback={null}>
                              <Home
                                siteId={Number(props.match.params.siteId)}
                                key={props.match.params.siteId}
                              />
                            </Suspense>
                            <Header routeTitle="Home" />
                          </Route>

                          <Route exact path="/sites/:siteId/profile">
                            <Suspense fallback={null}>
                              <Profile />
                            </Suspense>
                            <Header routeTitle="Profile" />
                          </Route>

                          {(!givenNotice || isAdminWithAdminBar) && (
                            <Route
                              exact
                              path="/sites/:siteId/health-check/:healthCheckPath"
                              render={(props) => (
                                <>
                                  <Suspense fallback={null}>
                                    <HealthCheck
                                      siteId={Number(props.match.params.siteId)}
                                      healthCheckPath={
                                        props.match.params
                                          .healthCheckPath as HealthCheckPath
                                      }
                                    />
                                  </Suspense>
                                  <Header routeTitle="Desktop In-Content Ads" />{' '}
                                </>
                              )}
                            />
                          )}

                          <Route exact path="/sites/:siteId/payments">
                            <Suspense fallback={null}>
                              <Payments />
                            </Suspense>
                            <Header routeTitle="Payments" />
                          </Route>
                          <Route path="/sites/:siteId/settings">
                            <Suspense fallback={<SettingsFallback />}>
                              <Settings>
                                <Switch>
                                  <Route
                                    exact
                                    path="/sites/:siteId/settings/ad-setup"
                                  >
                                    <Suspense fallback={<SettingsFallback />}>
                                      <AdSetup />
                                    </Suspense>
                                    <Header routeTitle="Ad Setup" />
                                  </Route>
                                  <Route
                                    exact
                                    path="/sites/:siteId/settings/ad-settings"
                                  >
                                    <Suspense fallback={<SettingsFallback />}>
                                      <AdSettings />
                                    </Suspense>
                                    <Header routeTitle="Ad Settings" />
                                  </Route>
                                  <Route
                                    exact
                                    path="/sites/:siteId/settings/disable-ads"
                                  >
                                    <Suspense fallback={<SettingsFallback />}>
                                      <DisableAds />
                                    </Suspense>
                                    <Header routeTitle="Disable Ad Settings" />
                                  </Route>
                                  <Route
                                    exact
                                    path="/sites/:siteId/settings/in-content-ads"
                                  >
                                    <Suspense fallback={<SettingsFallback />}>
                                      <InContentAds />
                                    </Suspense>
                                    <Header routeTitle="In-Content Ad Settings" />
                                  </Route>

                                  <Route
                                    exact
                                    path="/sites/:siteId/settings/google-analytics-4"
                                  >
                                    <RenderIf
                                      isTrue={
                                        features &&
                                        features.includes('ga4_settings_page')
                                      }
                                    >
                                      <Suspense fallback={<SettingsFallback />}>
                                        <GA4 />
                                      </Suspense>
                                      <Header routeTitle="Google Analytics 4" />
                                    </RenderIf>
                                  </Route>
                                  <Route
                                    exact
                                    path="/sites/:siteId/settings/uplift"
                                  >
                                    <Suspense fallback={<SettingsFallback />}>
                                      <Uplift />
                                    </Suspense>
                                    <Header routeTitle="Uplift by Mediavine" />
                                  </Route>
                                  <Route
                                    exact
                                    path="/sites/:siteId/settings/opt-out"
                                  >
                                    <Suspense fallback={<SettingsFallback />}>
                                      <OptOutSettings />
                                    </Suspense>
                                    <Header routeTitle="Opt-Out Settings" />
                                  </Route>
                                  <Route
                                    exact
                                    path="/sites/:siteId/settings/privacy"
                                  >
                                    <Suspense fallback={<SettingsFallback />}>
                                      <PrivacySettings />
                                    </Suspense>
                                    <Header routeTitle="Privacy Settings" />
                                  </Route>
                                  <Route
                                    exact
                                    path="/sites/:siteId/settings/profile"
                                  >
                                    <Suspense fallback={<SettingsFallback />}>
                                      <ProfileSettings />
                                    </Suspense>
                                    <Header routeTitle="Profile Settings" />
                                  </Route>
                                  <Route
                                    exact
                                    path="/sites/:siteId/settings/users"
                                  >
                                    <Suspense fallback={<SettingsFallback />}>
                                      <UserSettings />
                                    </Suspense>
                                    <Header routeTitle="User Settings" />
                                  </Route>
                                  <Route
                                    exact
                                    path="/sites/:siteId/settings/video"
                                  >
                                    <Suspense fallback={<SettingsFallback />}>
                                      <VideoSettings />
                                    </Suspense>
                                    <Header routeTitle="Video Settings" />
                                  </Route>
                                  <Route
                                    exact
                                    path="/sites/:siteId/settings/grow"
                                  >
                                    <Suspense fallback={<SettingsFallback />}>
                                      <Grow />
                                    </Suspense>
                                    <Header routeTitle="Grow Settings" />
                                  </Route>
                                  <Route
                                    exact
                                    path="/sites/:siteId/settings/beta-center"
                                  >
                                    <Suspense fallback={<SettingsFallback />}>
                                      <BetaCenter />
                                    </Suspense>
                                    <Header routeTitle="Beta Center" />
                                  </Route>
                                  <Route
                                    exact
                                    path="/sites/:siteId/settings/psas"
                                  >
                                    <Suspense fallback={<SettingsFallback />}>
                                      <PSASettings />
                                    </Suspense>
                                    <Header routeTitle="PSAs Settings" />
                                  </Route>
                                  <Route
                                    exact
                                    path="/sites/:siteId/settings/premiere"
                                  >
                                    <Suspense fallback={null}>
                                      <PremiereSettings />
                                    </Suspense>
                                    <Header routeTitle="Premiere Settings" />
                                  </Route>
                                  <Route>
                                    <Error404 />
                                  </Route>
                                </Switch>
                              </Settings>
                            </Suspense>
                          </Route>
                          <Route path="/sites/:siteId/videos">
                            <Suspense fallback={null}>
                              <Videos>
                                <Switch>
                                  <Route exact path="/sites/:siteId/videos">
                                    <Suspense fallback={null}>
                                      <VideoCollection />
                                    </Suspense>
                                    <Header routeTitle="Videos" />
                                  </Route>
                                  <Route
                                    exact
                                    path="/sites/:siteId/videos/edit/:videoId"
                                  >
                                    <Suspense fallback={null}>
                                      <VideoEdit />
                                    </Suspense>
                                    <Header routeTitle="Video Edit" />
                                  </Route>
                                  <Route
                                    exact
                                    path="/sites/:siteId/videos/preview/:videoId"
                                  >
                                    <Suspense fallback={null}>
                                      <VideoPreview />
                                    </Suspense>
                                    <Header routeTitle="Video Preview" />
                                  </Route>
                                  <Route
                                    exact
                                    path="/sites/:siteId/videos/add-new"
                                  >
                                    <Suspense fallback={null}>
                                      <VideoAddNew />
                                    </Suspense>
                                    <Header routeTitle="Video Add New" />
                                  </Route>
                                  <Route>
                                    <Error404 />
                                  </Route>
                                </Switch>
                              </Videos>
                            </Suspense>
                          </Route>
                          <Route path="/sites/:siteId/playlists">
                            <Suspense fallback={null}>
                              <Playlists>
                                <Switch>
                                  <Route exact path="/sites/:siteId/playlists">
                                    <Suspense fallback={null}>
                                      <PlaylistCollection />
                                    </Suspense>
                                    <Header routeTitle="Playlists Collection" />
                                  </Route>
                                  <Route
                                    exact
                                    path="/sites/:siteId/playlists/edit/:playlistId"
                                  >
                                    <Suspense fallback={null}>
                                      <EditPlaylist />
                                    </Suspense>
                                    <Header routeTitle="Playlists Collection Edit" />
                                  </Route>
                                  <Route
                                    exact
                                    path="/sites/:siteId/playlists/add-new"
                                  >
                                    <Suspense fallback={null}>
                                      <NewPlaylist />
                                    </Suspense>
                                    <Header routeTitle="Playlists Collection Add New" />
                                  </Route>
                                  <Route>
                                    <Error404 />
                                  </Route>
                                </Switch>
                              </Playlists>
                            </Suspense>
                          </Route>
                          <Route>
                            <Error404 />
                          </Route>
                        </Switch>
                      </Site>
                    )}
                  />
                  <Route exact path="/sites">
                    <Suspense fallback={null}>
                      <Sites />
                    </Suspense>
                    <Header routeTitle="Your sites" />
                  </Route>
                  <Route>
                    <Error404 />
                  </Route>
                </Switch>
              </Navigation>
            </GrowProvider>
            {isAdmin && (
              <FeatureFlag flag="admin_bar">
                <AdminBar
                  siteId={siteIdNum}
                  isAdminView={isAdminViewToggled}
                  toggleAdminView={setIsAdminViewToggled}
                />
              </FeatureFlag>
            )}
          </HealthChecksDataContext.Provider>
        </AdminBarProvider>
      </UserFavoritesProvider>
      {process.env.NODE_ENV !== 'test' && (
        <Route exact path="/">
          <Redirect from="/" to={`/sites/${defaultSite || ''}`} />
        </Route>
      )}
      {/* We do this redirect so that users who have these specific permissions aren't taken to the reporting view because they won't have the right permissions to view the data and it will show them a 401 page */}
      {shouldRedirectByRole(
        userData.roles,
        userData.site_users,
        siteIdNum,
        'ad_settings'
      ) && (
        <Route exact path={`/sites/${siteId}`}>
          <Redirect
            from={`/sites/${siteId}`}
            to={`/sites/${siteId}/settings/ad-settings`}
          />
        </Route>
      )}
      {shouldRedirectByRole(
        userData.roles,
        userData.site_users,
        siteIdNum,
        'payment'
      ) && (
        <Route exact path={`/sites/${siteId}`}>
          <Redirect
            from={`/sites/${siteId}`}
            to={`/sites/${siteId}/payments`}
          />
        </Route>
      )}
      {shouldRedirectByRole(
        userData.roles,
        userData.site_users,
        siteIdNum,
        'video'
      ) && (
        <Route exact path={`/sites/${siteId}`}>
          <Redirect from={`/sites/${siteId}`} to={`/sites/${siteId}/videos`} />
        </Route>
      )}
      <OnRouteChange
        action={() => {
          if (!window.location.hash) {
            setTimeout(() => {
              window.scrollTo(0, 0)
            })
          }
        }}
      />
    </GoogleAnalyticsProvider>
  )
}

function RequiresLogout() {
  return (
    <Notice kind="warning" icon="account">
      <Grid>
        The URL you clicked requires you to be logged out of any other accounts.
        Please log out and try visiting the link again.
        <div>
          <LogoutButton kind="primary" color="warning">
            Click here to log out
          </LogoutButton>
        </div>
      </Grid>
    </Notice>
  )
}

/**
 * This checks if the user's roles contains owner, reporting, or admin
 * and returns a boolean vaue. This will return true if the user's
 * roles include one of the reporting roles.
 */
function shouldRedirectByRole(
  userRoles: Role[],
  siteUsers: { site_id: number; roles: Role[] }[],
  siteId: number | null,
  roleType: Role
) {
  const reportingRoles = ['owner', 'reporting']

  // Make sure user isn't admin
  const isAdmin = userRoles.includes('admin')

  // If env var roles array is defined, simply return false if owner role is provided
  let envRoles: string[] = []
  if (
    process.env.NODE_ENV === 'development' &&
    process.env.REACT_APP_SITE_USER_ROLE
  ) {
    envRoles = [...JSON.parse(process.env.REACT_APP_SITE_USER_ROLE)]
    const isOwner = envRoles.includes('owner')
    const hasReportingRoles = envRoles.some(
      (role: string) => reportingRoles.indexOf(role) >= 0
    )
    return !isOwner && !hasReportingRoles
  }

  // Get the user data for the current site from site_users
  const currentSiteUserInfo = siteUsers.find(
    (site_user: { site_id: number; roles: Role[] }) =>
      site_user.site_id === siteId
  )

  // Get the roles array from the current site user data
  const siteUserRoles = currentSiteUserInfo?.roles

  // Check if user has a reporting role
  const hasReportingRole = siteUserRoles?.some(
    (role: string) => reportingRoles.indexOf(role) >= 0
  )

  // Check if the user has the role that was passed in
  const hasNonReportingSiteUserRole = siteUserRoles?.includes(roleType)

  // If a user isn't an admin, doesn't have owner or reporting roles, and they only have one role, this will return true
  return !isAdmin && !hasReportingRole && hasNonReportingSiteUserRole
}
