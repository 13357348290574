import React, { useContext } from 'react'
import { Flex, Icon } from '@mediavine/ui'
import CancelButton from 'components/CancelButton'
import RenderIf from 'components/RenderIf'
import { FeatureFlagContext } from './FeatureFlag'
import styled from 'styled-components'
import { FetchStatus } from 'helpers/types'
import { useSendTrackingEvent } from 'helpers/trackingEvents'
import { ButtonProps } from '@mediavine/ui/dist/Button'
import { statusFlags } from 'helpers/constants'
import useIsAdmin from 'helpers/hooks/useIsAdmin'
import { AdminBarContext } from 'helpers/context'
import { Button, CircularProgress } from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { useIsMobile } from 'helpers/hooks'

interface ISettingsFooterProps {
  status: string
  formStatus: boolean
  onSubmit: (data: any) => void
  invalid?: string | boolean
  btnText?: string
  useSticky?: boolean
}

export interface SaveButtonProps extends ButtonProps {
  id?: string
  status: string
  onClick: (data: any) => void
  children: string | React.ReactNode
  invalid?: boolean
  inverse?: boolean
}

interface TimeoutButtonProps {
  final: string | React.ReactNode
  initial: string | React.ReactNode
}

const { INITIAL, ACTIVE, COMPLETED, FINAL, FAILED } = statusFlags

const getPublishStatus = (status: string, children: React.ReactNode) => {
  switch (status) {
    case ACTIVE:
      return <CircularProgress size="18px" sx={{ color: '#ffffff' }} />
    case COMPLETED:
      return <TimeoutButton initial={<CheckCircleIcon />} final={children} />
    case FAILED:
      return (
        <TimeoutButton
          initial={<Icon kind="close" color="danger" />}
          final={children}
        />
      )
    default:
      return children
  }
}
// Timeout button that displays the spinner while waiting for the request to complete
// and then displays either a passing or failing icon for 2 seconds
const TimeoutButton = ({ initial, final }: TimeoutButtonProps) => {
  const [status, setStatus] = React.useState(INITIAL)

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setStatus(FINAL)
    }, 2000)
    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  })

  return <>{status === INITIAL ? initial : final}</>
}

export const SaveButton = ({
  status,
  onClick,
  children,
  invalid
}: SaveButtonProps) => (
  <Button
    variant="contained"
    onClick={(event: React.SyntheticEvent<HTMLButtonElement>) => {
      if (status === ACTIVE) {
        return
      }
      onClick(event)
    }}
    sx={{ minWidth: '150px', color: '#ffffff', fontSize: '14px' }}
    size="medium"
    disabled={invalid}
  >
    {getPublishStatus(normalizeStatus(status), children)}
  </Button>
)

export default function SettingsFooter({
  status,
  formStatus,
  onSubmit,
  invalid,
  btnText = 'Save Settings',
  useSticky = true
}: ISettingsFooterProps) {
  const { flags } = React.useContext(FeatureFlagContext)
  const isMaintenanceMode = flags.includes('maintenance_mode')
  const sendTrackingEvent = useSendTrackingEvent()
  const isMobile = useIsMobile()

  // admin bar related
  const { isAdminBarOpen } = useContext(AdminBarContext)
  const isAdmin = useIsAdmin()
  const isAdminBarFlagSet = flags.includes('admin_bar')
  const isAdminBarActive = isAdmin && isAdminBarFlagSet && isAdminBarOpen

  // When the footer mounts, apply a bottom margin
  // so that it doesn't overlap the save button.
  // When it unmounts, remove the margin.
  React.useEffect(() => {
    let ic: HTMLDivElement | null
    getIntercomWidget().then((result) => {
      ic = result
      if (ic) {
        ic.style.transition = 'margin .2s ease'
        ic.style.marginBottom = '50px'
      }
    })
    return () => {
      if (ic) {
        ic.style.marginBottom = '0'
      }
    }
  }, [])

  const content = () => {
    return (
      <Flex
        gutter="sm"
        align="flex-start"
        {...(!useSticky ? { marginTop: '20px' } : {})}
        wrap
      >
        <CancelButton formStatus={formStatus} />
        <Flex justify="flex-end" gutter="sm">
          <SaveButton
            status={normalizeStatus(status)}
            onClick={(data: any) => {
              onSubmit(data)
              sendTrackingEvent({ name: 'settings_update' })
            }}
            invalid={Boolean(invalid) || isMaintenanceMode}
          >
            {btnText}
          </SaveButton>
        </Flex>
      </Flex>
    )
  }

  return (
    <>
      <RenderIf isTrue={useSticky}>
        <StickyWrap adminBarActive={isAdminBarActive} isMobile={isMobile}>
          <Flex gutter="sm" align="flex-start" wrap>
            <CancelButton formStatus={formStatus} />
            <Flex justify="flex-end" gutter="sm">
              <SaveButton
                status={normalizeStatus(status)}
                onClick={(data: any) => {
                  onSubmit(data)
                  sendTrackingEvent({ name: 'settings_update' })
                }}
                invalid={Boolean(invalid) || isMaintenanceMode}
              >
                {btnText}
              </SaveButton>
            </Flex>
          </Flex>
        </StickyWrap>
      </RenderIf>
      <RenderIf isTrue={!useSticky}>{content()}</RenderIf>
    </>
  )
}

/**
 * useRequest and useUpdate have different statuses
 * than Network. This accounts for that.
 */
export function normalizeStatus(status: FetchStatus | string) {
  if (status === 'fetching') return statusFlags.ACTIVE
  if (status === 'fetched') return statusFlags.COMPLETED
  return status
}

/**
 * Interface for the StickyWrap component's props
 */
interface StickyWrapProps {
  adminBarActive?: boolean
  isMobile?: boolean
}

/**
 * Wrapper to fix footer to bottom of page.
 */
const StickyWrap = styled.footer<StickyWrapProps>`
  margin: 24px -24px -24px;
  background: ${(props) => props.theme.colors.boxBg};
  position: sticky;
  z-index: 99;
  bottom: ${(props) =>
    props.adminBarActive && !props.isMobile ? '68.05px' : '0px'};
  box-shadow: ${(props) => props.theme.shadows.box};
  padding: 12px 6px;
  @media (min-width: 768px) {
    padding: 12px 24px;
  }
`

/**
 * Returns a promise that resolves once Intercom is on the page.
 * This way, we can adjust styles even if the widget isn't
 * present when the page loads.
 */
function getIntercomWidget(): Promise<HTMLDivElement | null> {
  return new Promise((resolve) => {
    function lookForIc(iteration: number) {
      // Give up after 10 tries.
      if (iteration > 10) resolve(null)

      // Look for widget. If it's there, resolve. If not, recursively try again.
      const intercomWidget = document.querySelector(
        '.intercom-lightweight-app-launcher'
      )
      if (intercomWidget) {
        resolve(intercomWidget as HTMLDivElement)
      } else {
        setTimeout(() => lookForIc(iteration + 1), 250)
      }
    }
    lookForIc(0)
  })
}
