import { stepIconClasses, Theme } from '@mui/material'
import { grey } from '@mui/material/colors'

export default function Stepper(theme: Theme) {
  return {
    MuiStepIcon: {
      styleOverrides: {
        root: {
          '&.MuiSvgIcon-root': {
            color: 'transparent',
            border: 'solid 1px',
            borderColor: grey[300],
            borderRadius: '50%',
            width: '48px',
            height: '48px',
            fontSize: '16px',

            '& text': {
              fill: grey[300]
            }
          },
          '&.Mui-active': {
            color: 'transparent',
            borderColor: theme.palette.primary.main,
            borderRadius: '50%',
            width: '48px',
            height: '48px',

            '& text': {
              fill: theme.palette.primary.main
            }
          },
          [`&.${stepIconClasses.error}`]: {
            color: theme.palette.error.main,
            borderColor: theme.palette.error.main
          },
          '&.Mui-completed': {
            color: theme.palette.primary.main,
            border: 'none'
          }
        }
      }
    },
    MuiStepConnector: {
      styleOverrides: {
        root: {
          top: '24px',
          left: 'calc(-50% + 24px)',
          right: 'calc(50% + 24px)'
        },
        line: {
          borderColor: grey[300]
        }
      }
    },
    MuiStepIconText: {
      styleOverrides: {
        root: {
          fonSize: '16px'
        }
      }
    }
  }
}
