import React from 'react'

interface ILinkProps {
  children: React.ReactNode
  href: string
  style?: React.CSSProperties
}

export default function ExternalLink({ children, ...props }: ILinkProps) {
  return (
    <a {...props} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  )
}
