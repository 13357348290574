let lastActivityTime = Date.now()
let minXOffset = 0
let maxXOffset = 0
let minYOffset = 0
let maxYOffset = 0

function getPageOffsets() {
  const documentElement = document.documentElement
  if (documentElement) {
    return [
      documentElement.scrollLeft || window.pageXOffset,
      documentElement.scrollTop || window.pageYOffset
    ]
  }

  return [0, 0]
}

export function resetMaxScrolls() {
  const offsets = getPageOffsets()

  const x = offsets[0]
  minXOffset = x
  maxXOffset = x

  const y = offsets[1]
  minYOffset = y
  maxYOffset = y
}

function updateMaxScrolls() {
  const offsets = getPageOffsets()

  const x = offsets[0]
  if (x < minXOffset) {
    minXOffset = x
  } else if (x > maxXOffset) {
    maxXOffset = x
  }

  const y = offsets[1]
  if (y < minYOffset) {
    minYOffset = y
  } else if (y > maxYOffset) {
    maxYOffset = y
  }
}

function activityHandler() {
  const now = new Date()
  lastActivityTime = now.getTime()
}

function scrollHandler() {
  updateMaxScrolls()
  activityHandler()
}

export function initActivityListeners() {
  const documentHandlers = [
    'click',
    'mouseup',
    'mousedown',
    'mousemove',
    'keypress',
    'keydown',
    'keyup'
  ]
  const windowHandlers = ['resize', 'focus', 'blur']
  const listener =
    (element: Document | Window, handler = activityHandler) =>
    (ev: string) =>
      element.addEventListener(ev, handler)

  documentHandlers.forEach(listener(document))
  windowHandlers.forEach(listener(window))
  listener(window, scrollHandler)('scroll')
}

export function getLastActivityTime() {
  return lastActivityTime
}

export function getMinMaxOffsets() {
  return {
    minXOffset,
    maxXOffset,
    minYOffset,
    maxYOffset
  }
}
