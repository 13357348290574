import React from 'react'
import { useLocation } from 'react-router-dom'
import { SiteDataContext } from 'state/context/site/siteCtx'

interface IWorkerProps {
  location: { pathname: string }
  action: () => void
  siteTitle: string
}

// https://stackoverflow.com/questions/53058110/stop-reach-router-scrolling-down-the-page-after-navigating-to-new-page/56996986#56996986

class OnRouteChangeWorker extends React.Component<IWorkerProps> {
  componentDidUpdate(prevProps: IWorkerProps) {
    const { location, action } = this.props

    // After we redirect someone from accessing the root, we need to refresh the page so that
    // they get actual site context
    if (
      /sites\/\d+$/.test(location.pathname) &&
      prevProps.location.pathname === '/'
    ) {
      window.location.reload()
    }

    if (location.pathname !== prevProps.location.pathname) {
      action()
    }
  }
  render = () => null
}

export default function OnRouteChange({ action }: { action: () => void }) {
  const { title } = React.useContext(SiteDataContext)
  const location = useLocation()
  return (
    <OnRouteChangeWorker
      location={location}
      action={action}
      siteTitle={title}
    />
  )
}
