import { Theme } from '@mui/material/styles'

export default function Autocomplete(theme: Theme) {
  return {
    MuiAutocomplete: {
      styleOverrides: {
        noOptions: {
          paddingTop: '80px',
          paddingBottom: '88px',
          textAlign: 'center'
        },
        loading: {
          paddingTop: '80px',
          paddingBottom: '88px',
          textAlign: 'center'
        }
      }
    }
  }
}
