import React from 'react'
import { withTheme } from 'styled-components'

const colors = [
  'primary',
  'success',
  'warning',
  'danger',
  'alternate',
  'chartPrimary',
  'chartSecondary',
  'chartPrimaryLt',
  'chartSecondaryLt',
  'chartTertiary'
]

const SVGGlobals = withTheme(({ theme }) => (
  <svg style={{ position: 'absolute', height: '0' }}>
    <defs>
      {/* https://stackoverflow.com/questions/6088409/svg-drop-shadow-using-css3 */}
      <filter id="shadow" height="130%">
        <feGaussianBlur in="SourceAlpha" stdDeviation="3" />
        <feOffset dy="2" result="offsetblur" />
        <feComponentTransfer>
          <feFuncA type="linear" slope="0.1" />
        </feComponentTransfer>
        <feMerge>
          <feMergeNode />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
      <filter
        id={`bar-hover-glow`}
        x="-50%"
        y="-50%"
        height="200%"
        width="200%"
      >
        <feDropShadow
          dx="-2"
          dy="-2"
          stdDeviation="2"
          floodColor={theme.colors.primary}
        />
        <feDropShadow
          dx="2"
          dy="2"
          stdDeviation="2"
          floodColor={theme.colors.primary}
        />
      </filter>
      {colors.map((color) => (
        <filter
          key={color}
          id={`${color}-glow`}
          x="-50%"
          y="-50%"
          height="200%"
          width="200%"
        >
          <feDropShadow
            dx="0"
            dy="0"
            stdDeviation="2"
            floodColor={theme.colors[color]}
          />
        </filter>
      ))}
      {colors.map((color) => (
        <linearGradient
          key={color}
          id={`${color}-grad`}
          x1="0%"
          y1="0%"
          x2="0%"
          y2="100%"
        >
          <stop offset="0%" stopColor={theme.colors[color]} stopOpacity="10%" />
          <stop offset="100%" stopColor={theme.colors[color]} stopOpacity="0" />
        </linearGradient>
      ))}
    </defs>
  </svg>
))
export default SVGGlobals
