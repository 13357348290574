import * as React from 'react'

export interface IRenderIf {
  isTrue: boolean | undefined
  children: React.ReactNode
}

/**
 * RenderIf Component.
 * @returns a component that only renders conditionally.
 */
const RenderIf: React.FC<IRenderIf> = ({ isTrue, children }: IRenderIf) => (
  <>{isTrue ? children : null}</>
)

export default RenderIf
