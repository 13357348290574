import React, { useEffect, useState } from 'react'
import { Box, Button, Flex, Grid, H4, Icon, Text, Network } from '@mediavine/ui'
import { getApi } from 'helpers/data'
import { useIsMobile, useUpdate } from 'helpers/hooks'
import { ProModalValue } from 'helpers/types'
import styled from 'styled-components'
import ExternalLink from 'components/ExternalLink'
import LoginModal from 'components/modals/LoginModal'
import { SiteDataContext } from 'state/context/site/siteCtx'
import { useHasRole } from 'components/RequiresRole'

const PRO_COLOR = '#3E74C1'

export const SESSION_STORAGE_PRO_DISMISSED_IDS_VAR = 'dismissed_pro_modal_ids'

const HeaderImg = styled.img.attrs({
  src: `${process.env.PUBLIC_URL}/pro-modal-leaves.svg`,
  'data-pin-nopin': 'true'
})`
  position: absolute;
  top: 0;
  right: 0;
  min-width: auto;
  min-height: auto;
  width: 200px;
  height: 275px;
`

const ProIcon = styled.img.attrs({
  src: `${process.env.PUBLIC_URL}/pro-icon.svg`,
  'data-pin-nopin': 'true'
})``

const DismissButton = styled(Text)`
  cursor: pointer;
`

const ProModalStar = styled(Icon).attrs({
  color: '#75a2f4',
  kind: 'star'
})`
  margin-bottom: 7px;
  width: 15px;
  margin-right: 11.5px;
`

const ButtonContainer = styled(Grid)<{ isMobile: boolean }>`
  display: flex;
  justify-content: ${({ isMobile }) => (isMobile ? 'center' : 'flex-end')};
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
`

const ModalButton = styled(Button)<{ isMobile: boolean }>`
  width: ${({ isMobile }) => (isMobile ? '100%' : 'auto')};
  padding-top: ${({ isMobile }) => (isMobile ? '16px' : '')};
  padding-bottom: ${({ isMobile }) => (isMobile ? '16px' : '')};
  font-weight: ${({ isMobile }) => (isMobile ? '900' : '700')};
`

export default function ProModal() {
  const [close, setClose] = React.useState(false)
  const [declined, setDeclined] = React.useState(false)
  const isMobile = useIsMobile()
  const {
    userData,
    userData: { roles }
    // @ts-ignore
  } = React.useContext<UserContextProps>(Network.JWTContext)
  const {
    refetchSiteData,
    revenue_share_pro,
    id,
    pro_accepted,
    pro_invited,
    premiere_accepted,
    offering_id
  } = React.useContext(SiteDataContext)
  const [hasRole] = useHasRole(userData, ['owner'], id)
  const revShare =
    offering_id === 1 ? Math.min(100, revenue_share_pro * 100).toFixed(1) : 85

  const { execute: handleInvite } = useUpdate(
    async (modalValue: ProModalValue) => {
      const api = getApi()
      await api
        .patch(`/sites/${id}/site_settings/profile_settings`, {
          pro_accepted: modalValue
        })
        .then(() => {
          if (modalValue) refetchSiteData()
        })

      setClose(true)
    },
    {}
  )

  // check to see if user dismissed the modal
  const [proModalHasBeenDismissed, setProModalHasBeenDismissed] =
    useState(false)
  useEffect(() => {
    const currentSessionStorageIds = window.sessionStorage.getItem(
      SESSION_STORAGE_PRO_DISMISSED_IDS_VAR
    )

    if (
      currentSessionStorageIds &&
      JSON.parse(currentSessionStorageIds).includes(id.toString())
    )
      setProModalHasBeenDismissed(true)
  }, [id])

  if (close) {
    return null
  }

  return (
    <LoginModal
      id="pro-modal"
      heading="Join Mediavine Pro?"
      height={isMobile ? '80vh' : 'auto'}
      width={isMobile ? undefined : '648px'}
      open={
        (pro_invited === true &&
          !premiere_accepted &&
          pro_accepted === null &&
          !proModalHasBeenDismissed &&
          close === false &&
          !roles.includes('admin') &&
          hasRole) ||
        false
      }
      useHeader={false}
    >
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          overflowY: 'auto'
        }}
      >
        <Box
          style={{
            width: isMobile ? '100%' : '600px',
            height: isMobile ? '100vh' : 'auto',
            margin: 'auto',
            position: 'relative',
            padding: 0
          }}
        >
          <HeaderImg />
          <Box padding={isMobile ? '80px 47px 24px 48px' : '16px 24px 24px'}>
            <Grid
              style={{ marginBottom: isMobile ? '66px' : '32px' }}
              gutter={isMobile ? '24px' : '16px'}
            >
              <Flex justify="center">
                <ProIcon />
              </Flex>
              <H4
                id="pro-modal-header"
                style={{ textAlign: 'center', fontFamily: 'Roboto Slab' }}
              >
                Join Mediavine Pro?
              </H4>
              <Text
                style={{
                  paddingTop: '12px',
                  paddingBottom: '4px',
                  letterSpacing: '.5px'
                }}
              >
                {declined ? (
                  <>
                    Are you sure you don’t want to join the Mediavine Pro
                    program? <br />
                    These are the benefits you will be missing out on:
                  </>
                ) : (
                  <>
                    Congratulations! Consider this your official invitation to
                    Mediavine Pro! Accepting this invitation will enroll you in
                    the following benefits:
                  </>
                )}
              </Text>
              <Box style={{ width: '270px', padding: '0', margin: 'auto' }}>
                <Flex justify="flex-start">
                  <ProModalStar />
                  <ExternalLink
                    href="https://help.mediavine.com/en/articles/6518833-pro-revenue-share"
                    style={{ color: PRO_COLOR }}
                  >
                    <Text size="16px">85% Minimum Revenue Share</Text>
                  </ExternalLink>
                </Flex>
                <Flex justify="flex-start">
                  <ProModalStar />
                  <Text size="16px">Dedicated Pro Support Team</Text>
                </Flex>
                <Flex justify="flex-start">
                  <ProModalStar />
                  <Text size="16px">Special Facebook Group Access</Text>
                </Flex>
                <Flex justify="flex-start" align="baseline">
                  <ProModalStar size="40px !important" />
                  <Text
                    size="16px"
                    lineHeight={1.2}
                    style={{ marginBottom: '7px' }}
                  >
                    Apply to add additional sites to Mediavine Ad Management at
                    a lower traffic threshold of 25k
                  </Text>
                </Flex>
              </Box>
              <Text
                size="12px"
                style={{
                  paddingTop: '4px',
                  paddingRight: '18px',
                  letterSpacing: '.4px'
                }}
              >
                As of today, your revenue share will be {revShare}%, with
                acceptance of this Pro invitation. A formal addendum to your
                agreement will be provided to you in the coming weeks.
              </Text>
            </Grid>
            <Flex
              direction={isMobile ? 'column' : 'row'}
              justify={isMobile ? 'center' : 'space-between'}
            >
              <DismissButton
                color={PRO_COLOR}
                weight="700"
                style={
                  isMobile ? { paddingBottom: '34px' } : { paddingLeft: '4px' }
                }
                onClick={() => {
                  let currentSessionStorageIds = window.sessionStorage.getItem(
                    SESSION_STORAGE_PRO_DISMISSED_IDS_VAR
                  )
                  currentSessionStorageIds = currentSessionStorageIds
                    ? JSON.parse(currentSessionStorageIds)
                    : []
                  const newSessionStorageIds: string[] = [
                    ...(currentSessionStorageIds ?? []),
                    id.toString()
                  ]
                  window.sessionStorage.setItem(
                    `${SESSION_STORAGE_PRO_DISMISSED_IDS_VAR}`,
                    JSON.stringify(newSessionStorageIds)
                  )
                  setClose(true)
                }}
              >
                Dismiss
              </DismissButton>
              <ButtonContainer isMobile={isMobile} gutter="0">
                <ModalButton
                  style={{
                    marginRight: '8px',
                    marginBottom: isMobile ? '32px' : 'initial'
                  }}
                  color={PRO_COLOR}
                  onClick={() => {
                    if (declined) {
                      handleInvite(false)
                    }
                    setDeclined(true)
                  }}
                  isMobile={isMobile}
                >
                  {declined ? "I'm sure" : 'Decline'}
                </ModalButton>
                <ModalButton
                  kind="primary"
                  color={PRO_COLOR}
                  onClick={() => handleInvite(true)}
                  isMobile={isMobile}
                  style={{ marginBottom: isMobile ? '80px' : 'initial' }}
                >
                  {declined ? "I'd Like To Join Mediavine PRO" : 'Accept'}
                </ModalButton>
              </ButtonContainer>
            </Flex>
          </Box>
        </Box>
      </div>
    </LoginModal>
  )
}
