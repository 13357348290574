import React from 'react'
import { useRequest, useUpdate } from 'helpers/hooks'
import { Notice, Grid, InputGroup, Label, Input, Button } from '@mediavine/ui'
import DataPlaceholder from './DataPlaceholder'
import { Form, Field } from 'react-final-form'
import { FormLabelError } from './FormHelpers'
import { getApi } from 'helpers/data'
import { AxiosResponse } from 'axios'
import { IData as ProfileData } from 'views/Settings/Profile'

export default function ConfirmAddressForm({ id }: { id: number }) {
  const { data } = useRequest({
    initialParams: { id },
    fetchData: async (params) => {
      if (!id) return null
      const api = getApi()
      const res = (await api.get(
        `/sites/${params.id}/site_settings/profile_settings`
      )) as AxiosResponse<{ site: ProfileData }>
      return {
        address1: res.data.site.address1,
        city: res.data.site.city,
        state: res.data.site.state,
        country: res.data.site.country,
        zipcode: res.data.site.zipcode,
        phone_number: res.data.site.phone_number,
        // We want to hardcode this on the response value
        // so that it's automatically set when we make an
        // update request.
        verify_address: true
      }
    }
  })

  const { execute, updateStatus } = useUpdate(
    async (formData: AddressFields, params) => {
      const api = getApi()
      await api.patch(
        `/sites/${params.id}/site_settings/profile_settings`,
        formData
      )
      // FIXME: To update the site model, we need to refresh the page.
      setTimeout(() => {
        window.location.reload()
      }, 500)
    },
    { id }
  )

  if (updateStatus === 'fetched') {
    return <Notice kind="success">Thanks!</Notice>
  }

  if (!data) {
    return <DataPlaceholder />
  }

  return (
    <Form
      initialValues={data}
      onSubmit={execute}
      validate={validateAddressFields}
      render={({ handleSubmit, hasValidationErrors }) => (
        <Grid>
          <Field
            name="address1"
            render={({ input, meta }) => (
              <InputGroup>
                <Label kind="bold" htmlFor="address1">
                  Street Address*
                  {meta.error && meta.touched && (
                    <FormLabelError>{meta.error}</FormLabelError>
                  )}
                </Label>
                <Input
                  placeholder="Enter your address..."
                  {...input}
                  {...{ 'data-private': '' }}
                />
              </InputGroup>
            )}
          />

          <Field
            name="city"
            render={({ input, meta }) => (
              <InputGroup>
                <Label kind="bold" htmlFor="city">
                  City*
                  {meta.error && meta.touched && (
                    <FormLabelError>{meta.error}</FormLabelError>
                  )}
                </Label>
                <Input
                  placeholder="Enter your city..."
                  {...input}
                  {...{ 'data-private': '' }}
                />
              </InputGroup>
            )}
          />

          <Field
            name="state"
            render={({ input, meta }) => (
              <InputGroup>
                <Label kind="bold" htmlFor="state">
                  State*
                  {meta.error && meta.touched && (
                    <FormLabelError>{meta.error}</FormLabelError>
                  )}
                </Label>
                <Input
                  placeholder="Enter your state..."
                  {...input}
                  {...{ 'data-private': '' }}
                />
              </InputGroup>
            )}
          />

          <Field
            name="country"
            render={({ input, meta }) => (
              <InputGroup>
                <Label kind="bold" htmlFor="country">
                  Country*
                  {meta.error && meta.touched && (
                    <FormLabelError>{meta.error}</FormLabelError>
                  )}
                </Label>
                <Input
                  placeholder="Enter your country..."
                  {...input}
                  {...{ 'data-private': '' }}
                />
              </InputGroup>
            )}
          />

          <Field
            name="zipcode"
            render={({ input, meta }) => (
              <InputGroup>
                <Label kind="bold" htmlFor="zipcode">
                  Zipcode*
                  {meta.error && meta.touched && (
                    <FormLabelError>{meta.error}</FormLabelError>
                  )}
                </Label>
                <Input
                  placeholder="Enter your zipcode..."
                  {...input}
                  {...{ 'data-private': '' }}
                />
              </InputGroup>
            )}
          />

          <Field
            name="phone_number"
            render={({ input, meta }) => (
              <InputGroup>
                <Label kind="bold" htmlFor="phone_number">
                  Phone Number*
                  {meta.error && meta.touched && (
                    <FormLabelError>{meta.error}</FormLabelError>
                  )}
                </Label>
                <Input
                  placeholder="(555) 555-5555"
                  {...input}
                  {...{ 'data-private': '' }}
                />
              </InputGroup>
            )}
          />

          <div>
            <Button onClick={handleSubmit} disabled={hasValidationErrors}>
              Looks good!
            </Button>
          </div>
        </Grid>
      )}
    ></Form>
  )
}

type AddressFields = {
  address1: string
  city: string
  state: string
  country: string
  zipcode: string
  phone_number: string
}

function validateAddressFields(values: AddressFields) {
  const errors: { [k: string]: string } = {}
  if (!values.address1) {
    errors.address1 = 'Required'
  }
  if (!values.city) {
    errors.city = 'Required'
  }
  if (!values.state) {
    errors.state = 'Required'
  }
  if (!values.country) {
    errors.country = 'Required'
  }
  if (!values.zipcode) {
    errors.zipcode = 'Required'
  }
  if (!values.phone_number) {
    errors.phone_number = 'Required'
  }
  return errors
}
