import nanoid from 'nanoid'
import { getFillValue } from 'helpers/colors'
import { HealthCheckSummary, HealthCheckStatus } from './types'
import Axios from 'axios'
import cache from './cache'
import scrubber from './scrubber'

export interface IHasUUID {
  uuid: string
}

/**
 * Given an array, assign a UUID to each. This should
 * be done for any array value in a response that can
 * be modified or added to by a user, since we need to
 * be able to have unique IDs that can be generated by
 * the client.
 */
export const assignUUIDToItems = <T>(items: Array<T>) =>
  items.map(makeItemWithUUID)

/**
 * Given an item, assign a UUID with non-word characters
 * stripped so that it can be used as an id attribute.
 */
export const makeItemWithUUID = <T>(item: T) => ({
  ...item,
  uuid: nanoid().replace(/\W/g, '')
})

/**
 * Given an array, remove UUID for items. This should be
 * done before making requests so that we don't pass the
 * client ID to the API.
 */
export const stripUUID = <T extends IHasUUID>(items: Array<T>) =>
  items.map(({ uuid, ...item }) => item)

/**
 * Sorts values in descending order of severity, also prioritizing numeric values in the same "tier"
 */
export const sortByStatus =
  (statuses: HealthCheckSummary) =>
  (a: keyof HealthCheckSummary, b: keyof HealthCheckSummary) => {
    if (!statuses) return 0

    const getWeight = (value: HealthCheckStatus | number | null) => {
      if (typeof value === 'number') return -1
      return 0
    }

    const aStatus = statuses[a]
    const bStatus = statuses[b]
    const aValue = getFillValue(aStatus, a)
    const bValue = getFillValue(bStatus, b)
    const aWeight = getWeight(aStatus)
    const bWeight = getWeight(bStatus)

    const values = {
      bodyLt: 0, //-1,
      danger: 0,
      warning: 2,
      success: 4,
      primary: 6,
      primaryLt: 6
    }

    if (
      aValue === bValue &&
      typeof aStatus === 'number' &&
      typeof bStatus === 'number'
    ) {
      return aStatus - bStatus
    }

    const score = values[aValue] + aWeight - (values[bValue] + bWeight)
    return score
  }

/** Returns the sum of an array of values from an object based on a given key */
export const sumByKey = <T>(reports: T[], key: keyof T): number =>
  reports.filter(Boolean).reduce((total, item) => total + Number(item[key]), 0)

/**
 * Given a number, return a shortened version, e.g.
 *
 * 1000 => 1k
 * 1100 => 1.1k
 * 1000000 => 1m
 */
export const formatLargeNumber = (num: number) =>
  new Intl.NumberFormat('en-US', {
    //@ts-ignore – Typings are wrong
    notation: 'compact',
    compactDisplay: 'short'
  }).format(num)

/**
 * Returns an Axios instance authenticated
 * with current user's JWT.
 *
 * If the page url includes ?REDACT, we include
 * a scrubber to remove sensitive data like page,
 * video, or site titles. This is used so that the
 * support team can take screenshots of a site
 * without revealing the actual site.
 *
 * Adds optional debounced functionality through axios get interceptors
 * if debounceValue is > 0 then we add an interceptor. The interceptor's function
 * uses the cache helper to make sure to only send a GET request to endpoint ABC if
 * enough time has passed since the last request to endpoint ABC.
 */
export function getApi(version: string = 'v1', debounceValue = 0) {
  const jwt = getJwt()
  const instance = Axios.create({
    baseURL:
      version === 'v1'
        ? process.env.REACT_APP_API
        : process.env.REACT_APP_API_V2,
    headers: {
      Authorization: `Bearer ${jwt}`
    }
  })

  // If debounceValue is greater than zero we add an axios interceptor to debounce
  if (debounceValue > 0) {
    instance.interceptors.request.use(
      (requestConfig) => {
        // Helper cache vars
        // if var has not been defined create it
        const lastRequests = cache.get('lastRequests') || {}
        // Try to grab the last request time of the current request
        const lastRequestTime = requestConfig.url
          ? lastRequests[requestConfig.url]
          : undefined

        // Get the current time
        const currentTime = new Date()

        // If lastRequestTime is valid then we calculate the elapsed time, if not we set the elapsed time to a value
        // greater than the debounced value plus 1000 millisecs. The elapsedTime is going to be in milliseconds
        const elapsedTime: number = lastRequestTime
          ? currentTime.getTime() - lastRequestTime
          : debounceValue + 1000

        // If lastRequestUrl is undefined or elapsedTime is greater than the debounceValue we are allowed
        // to send a new request
        if (elapsedTime > debounceValue) {
          // If request is valid then add it to the cache object, possibly overwriting it if the request
          // was already there
          if (requestConfig?.url) {
            cache.set({
              lastRequests: {
                ...lastRequests,
                [requestConfig.url]: new Date().getTime()
              }
            })
          }
          // send the request
          return requestConfig
        }
      },
      (error) => {
        console.log('Axios GET interceptor error: ', error)
      }
    )
  }

  // If a user is in "redact" mode, scrub incoming data.
  if (window.location.href.includes('REDACT')) {
    instance.interceptors.response.use(scrubber)
  }

  return instance
}

/**
 * Given an object, remove whitespace from values.
 */
export function stripSpacesFromObj<
  T extends { [key: string]: string | unknown }
>(data: T): T {
  const cleanData = { ...data }
  const keys = Object.keys(data) as (keyof T)[]
  keys.forEach((key) => {
    if (typeof data[key] === 'string') {
      const value = data[key] as string
      cleanData[key] = value.trim() as any
    }
  })
  return cleanData
}

/**
 * Given a set of primary data, return a callback that will associate
 * a comparison item with the same `i` value in the primary range.
 * We use the `i` value to determine colors in bar graphs, so this is
 * necessary to ensure that the primary and comparison graphs use the
 * same color.
 *
 * Usage: comparison.map(associateComparisonByPrimary(primary))
 */
export const associateComparisonByPrimary =
  <T extends { i: number }>(primary: T[], key: keyof T) =>
  (item: T) => {
    const match = primary.find((i) => i[key] === item[key])
    return {
      ...item,
      i: match ? match.i : item.i
    }
  }

/**
 * Can be passed to .map for an array to add
 * an `i` value to each item corresponding to the index.
 * This is used for arrays where a color is given
 * based on the index.
 */
export function assignIndex<T>(m: T, i: number) {
  return { ...m, i }
}

// This is used with the parse={identity} props on all of the social media fields. It allows us to save empty string values because react-final-form doesn't allow that by default and this fix hasn't been added to the library yet.
// Check out the GitHub issue here: https://github.com/final-form/react-final-form/issues/130#issuecomment-425482365
export const identity = (value: any) => value

export function isPresent<T>(t: T | undefined | null | void): t is T {
  return t !== undefined && t !== null
}

export function getJwt(): string | null {
  try {
    /** User chose to remember device */
    const { jwt: localJwt } = JSON.parse(
      localStorage.getItem('dashboard') || '{}'
    )

    if (localJwt) {
      return localJwt
    }

    /** User did not chose to remember device */
    const { jwt: sessionJwt } = JSON.parse(
      sessionStorage.getItem('dashboard') || '{}'
    )

    return sessionJwt
  } catch (e) {
    return null
  }
}
