import React from 'react'
import styled from 'styled-components'
import { Grid, H6, Box, Notice, Text, Button } from '@mediavine/ui'
import { CompoundBox } from '@mediavine/ui'

/**
 * Renders a skeleton that loosely matches
 * the display of the Revenue and Earnings & Revenue
 * sections:
 *
 * [=====]
 * [=] [=]
 *
 * This is used by the Reports area when a site hasn't
 * loaded the information we need to begin making requests.
 */
function ReportsSkeleton() {
  return (
    <Grid>
      <SkeletonItem />
      <Grid autoFit="minmax(200px, 1fr)">
        <SkeletonItem />
        <SkeletonItem />
      </Grid>
    </Grid>
  )
}

interface SingleReportSkeletonProps {
  header: string
}

/**
 * Renders a semi-opaque CompoundBox as a placeholder
 * for items
 *
 * Used when you'd like the skeleton to indicate the
 * section of data that will be loaded.
 *
 * Renders like:
 *
 * |-------|
 * | [===] |
 * |[=====]|
 * |-------|
 *
 * to indicate the presence of a graph and a table.
 */
function SingleReportSkeleton({ header }: SingleReportSkeletonProps) {
  return (
    <CompoundBox
      border="none"
      shadow="box"
      header={<H6 color="bodyLt">{header}</H6>}
      style={{ opacity: 0.8 }}
    >
      <Box bg="none">
        <SkeletonItem />
      </Box>
      <SkeletonItem />
    </CompoundBox>
  )
}

interface SingleReportErrorSkeletonProps {
  header: string
  onRetry(): void
  canRetry: boolean
  text?: string
}

/**
 * Renders a placeholder for individual reports
 * that failed to load.
 *
 * TODO: Move this
 */
function SingleReportErrorSkeleton({
  header,
  onRetry,
  canRetry,
  text
}: SingleReportErrorSkeletonProps) {
  return (
    <CompoundBox
      border="none"
      shadow="box"
      header={<H6 color="bodyLt">{header}</H6>}
    >
      <Box bg="none">
        <Notice kind="danger">
          <Grid gutter="sm">
            <Text>{text || "We couldn't load this report."}</Text>
            {canRetry && (
              <div>
                <Button kind="primary" color="danger" onClick={onRetry}>
                  Retry?
                </Button>
              </div>
            )}
          </Grid>
        </Notice>
      </Box>
    </CompoundBox>
  )
}

/**
 * Renders a primitive skeleton item Just a rectangle
 * with an animated gradient.
 */
const SkeletonItem = styled.div`
  height: 400px;
  background: ${(props) => props.theme.skeletonGradient};
  border-radius: ${(props) => props.theme.borderRadius};
  background-size: 50%;
  animation: skeleton 3s infinite ease-out;

  @keyframes skeleton {
    0% {
      background-position: 0%;
    }
    100% {
      background-position: 100%;
    }
  }
`

export {
  SkeletonItem,
  ReportsSkeleton,
  SingleReportSkeleton,
  SingleReportErrorSkeleton
}
