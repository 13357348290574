import React from 'react'
import { Flex, Grid, Text, Box, Notice } from '@mediavine/ui'
import styled from 'styled-components'

import MediavineLogo from './MediavineLogo'

const AuthLayoutBackground = styled.div`
  background-color: ${(p) => p.theme.colors.primary};
  background-image: ${(p) => `url(${process.env.PUBLIC_URL}/login-bg.jpg)`};
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  height: 100vh;
`

interface AuthLayoutProps {
  title?: string
  error?: any
  showError?: boolean
  children: React.ReactNode
}

const errorMessage = (errorCode: string) => {
  if (errorCode === 'invalid_credentials') {
    return 'Invalid Username/Password Combination'
  }
  return 'Error'
}

export default function AuthLayout(props: AuthLayoutProps) {
  return (
    <AuthLayoutBackground>
      <Flex height="75%" justify="center" align="flex-end">
        <Flex direction="column" maxWidth="396px">
          <Grid padding="lg md sm md" justify="center">
            <Box shadow="box" bg="bodyBg">
              <Grid justify="center" gutter="lg">
                <Grid gutter="sm">
                  <MediavineLogo width="100%" />
                  <LogoLabelWrap>
                    <LogoLabel>Dashboard</LogoLabel>
                  </LogoLabelWrap>
                </Grid>
                {props.title && (
                  <Text
                    size="xl"
                    align="center"
                    color="primary"
                    weight="bold"
                    lineHeight="tight"
                  >
                    {props.title}
                  </Text>
                )}
                {props.children}
              </Grid>
            </Box>
          </Grid>
        </Flex>
      </Flex>
      <Flex justify="center" align="center">
        {props.error && props.showError && (
          <Grid padding="sm md md md" justify="center" maxWidth="396px">
            <Notice kind="danger">
              {/* @ts-ignore */}
              {errorMessage(props.error.response?.data.error)}
            </Notice>
          </Grid>
        )}
      </Flex>
    </AuthLayoutBackground>
  )
}

const LogoLabelWrap = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 2px;
    margin-top: -1px;
    background: ${(props) => props.theme.colors.border};
  }
`
const LogoLabel = styled.div`
  position: relative;
  display: inline-block;
  padding: ${(props) => props.theme.gutter.xs};
  color: ${(props) => props.theme.colors.bodyLt};
  text-transform: uppercase;
  background: ${(props) => props.theme.colors.bodyBg};
  font-size: ${(props) => props.theme.sizes.sm};
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
`
