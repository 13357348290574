import { useFeatureFlag } from 'helpers/hooks'

interface ReverseFeatureFlagProps {
  children: any
  flag?: string
}

export default function ReverseFeatureFlag({
  children,
  flag
}: ReverseFeatureFlagProps) {
  const isEnabled: boolean | null = useFeatureFlag(flag)

  if (isEnabled) return null

  return children
}
