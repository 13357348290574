import * as React from 'react'

export type ABTestingValue = {
  betaName: string | null | undefined
  betaVersion: string | null | undefined
}
const ABTestingContext = React.createContext<ABTestingValue>({
  betaName: '',
  betaVersion: ''
})

interface ABTestingProviderProps {
  betaName: string | null | undefined
  betaVersion: string | null | undefined
  children: React.ReactNode
}

export function ABTestingProvider({
  betaName,
  betaVersion,
  children
}: ABTestingProviderProps) {
  const value: ABTestingValue = React.useMemo(() => {
    return {
      betaName,
      betaVersion
    }
  }, [betaName, betaVersion])

  return (
    <ABTestingContext.Provider value={value}>
      {children}
    </ABTestingContext.Provider>
  )
}

export function useABTestingData() {
  return React.useContext(ABTestingContext)
}
