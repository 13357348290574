import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material'

const ConfirmDialogue: React.FC = () => {
  const reducerState = useSelector((state: RootState) => state?.confirmDialogue)
  const { isOpen, title, dialogueActions, content } = reducerState

  return (
    <Dialog
      open={isOpen}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions>{dialogueActions}</DialogActions>
    </Dialog>
  )
}

export default ConfirmDialogue
