import { createSlice } from '@reduxjs/toolkit'

export const toastInitialState: IGlobalToast = {
  toastMessage: '',
  errorCode: null,
  shouldBeDismissable: true,
  isShowing: false
}

export const globalToast = createSlice({
  name: 'globalToast',
  initialState: toastInitialState,
  reducers: {
    setProps: (state, action) => ({
      ...state,
      ...action.payload
    }),
    reset: () => toastInitialState
  }
})

export const { setProps, reset } = globalToast.actions

export interface IGlobalToast {
  toastMessage: string
  errorCode: null | string
  shouldBeDismissable: boolean
  isShowing: boolean
}
