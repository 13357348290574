import React, { useCallback } from 'react'
import { FORM_ERROR } from 'final-form'
import { Form, Field } from 'react-final-form'
import { Button, Grid, Notice, Input, Box } from '@mediavine/ui'
import AuthLayout from '../../components/AuthLayout'
import { getApi } from 'helpers/data'

interface NewPasswordFormShape {
  email: string
}

const INITIAL_VALUES: NewPasswordFormShape = {
  email: ''
}

export default function NewPassword() {
  const handleSubmit = useCallback((values: NewPasswordFormShape) => {
    const api = getApi()
    return api
      .post(`/passwords`, {
        email: values.email
      })
      .then(() => undefined)
      .catch((e) => {
        return {
          [FORM_ERROR]: "Couldn't send"
        }
      })
  }, [])

  return (
    <AuthLayout title="Forgot your password?">
      <Form<NewPasswordFormShape>
        initialValues={INITIAL_VALUES}
        onSubmit={handleSubmit}
      >
        {(formRenderProps) => (
          <>
            {formRenderProps.submitFailed && (
              <Notice kind="danger">{formRenderProps.submitError}</Notice>
            )}
            {formRenderProps.submitSucceeded && (
              <Notice kind="success">
                You will receive an email with instructions on how to reset your
                password in a few minutes.
              </Notice>
            )}

            <form onSubmit={formRenderProps.handleSubmit}>
              <Grid>
                <Field
                  name="email"
                  render={(fieldRenderProps) => (
                    <Box padding="sm" bg="bodyBg" shadow="box">
                      <Input
                        {...{ 'data-private': '' }}
                        kind="inline"
                        placeholder="Email Address"
                        disabled={
                          formRenderProps.submitting ||
                          formRenderProps.submitSucceeded
                        }
                        value={fieldRenderProps.input.value}
                        onChange={fieldRenderProps.input.onChange}
                      />
                    </Box>
                  )}
                />
                <Button
                  disabled={
                    formRenderProps.submitting ||
                    formRenderProps.submitSucceeded
                  }
                  kind="primary"
                  onClick={formRenderProps.handleSubmit}
                >
                  Send me password reset instructions.
                </Button>
              </Grid>
            </form>
          </>
        )}
      </Form>
    </AuthLayout>
  )
}
