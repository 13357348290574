import React, { useEffect } from 'react'
import styled from 'styled-components'
import SiteFooter from 'components/SiteFooter'
import { Button, Box, Icon, ScreenReader, Truncate } from '@mediavine/ui'
import MobileSideMenu from './MobileSideMenu'
import {
  TopLevel,
  UserLevel,
  SettingsLevel,
  SwitchSitesLevel,
  EXPANDED_WIDTH
} from './DesktopNavigation'

import { HideableNavigation } from './NoNavigation'
import { GlobalThemeContext } from 'helpers/theme'
import PremiereLogo from './PremiereLogo'
import ProLogo from './ProLogo'
import { SiteDataContext } from 'state/context/site/siteCtx'

export type View = 0 | 1 | 2 | 3

export const NONE = 0
export const USER = 1
export const SETTINGS = 2
export const SWITCH_SITES = 3

const TopNav = styled.nav<any>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  max-width: 100vw;
  background: ${(props) => props.theme.colors.bodyBg};
  box-shadow: ${(props) => props.theme.shadows.box};
  display: flex;
  z-index: 9999;
`

const Burger = styled.div`
  border-top: 1px solid ${(props) => props.theme.colors.primary};
  border-bottom: 1px solid ${(props) => props.theme.colors.primary};
  height: 5px;
  width: 18px;
  margin: 0.5em auto 0;
`

const MenuBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
const Main = styled.div`
  font-family: Roboto Slab;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${(props) => props.theme.gutter.sm};
  max-width: calc(100% - 53px);
  width: 85%;
`
const MainView = styled.main`
  padding: 51px 0 0;
`

export default function MobileNavigation({ children }: any) {
  const wrap = React.useRef<HTMLDivElement>(null)
  const { title } = React.useContext(SiteDataContext)
  const [secondaryMenuOpen, setSecondaryMenuOpen] =
    React.useState<boolean>(false)
  const [level, setLevel] = React.useState<View>(NONE)

  useEffect(() => {
    wrap.current?.addEventListener('click', (e: any) => {
      if (e.target.closest('a')) {
        setSecondaryMenuOpen(false)
        setLevel(NONE)
      }
    })
  }, [])

  // theme
  const { currentTheme } = React.useContext(GlobalThemeContext)
  const isPremiere = currentTheme === 'premiere'
  const isPro = currentTheme === 'pro'

  const getMenuLogo = () => {
    if (isPremiere)
      return (
        <div
          style={{
            width: '30px',
            height: '10px',
            fontSize: '9px',
            paddingLeft: '3px',
            paddingBottom: '10px'
          }}
        >
          <PremiereLogo />
        </div>
      )
    if (isPro)
      return (
        <div
          style={{
            width: '30px',
            height: '10px',
            fontSize: '7px',
            paddingLeft: '5px',
            marginBottom: '5px'
          }}
        >
          <ProLogo />
        </div>
      )

    return (
      <div
        style={{
          width: '30px',
          height: '10px',
          fontSize: '12px',
          padding: '0 0 15px'
        }}
      >
        <Icon kind="mediavine" size="lg" />
      </div>
    )
  }

  const getHeaderLogo = () => {
    if (isPremiere)
      return (
        <div style={{ width: '30px', height: '10px', fontSize: '9px' }}>
          <PremiereLogo />
        </div>
      )
    if (isPro)
      return (
        <div style={{ width: '30px', height: '10px', fontSize: '7px' }}>
          <ProLogo />
        </div>
      )

    return (
      <div style={{ width: '30px', height: '10px', fontSize: '12px' }}>
        <Icon kind="mediavine" size="lg" />
      </div>
    )
  }

  return (
    <div ref={wrap}>
      <HideableNavigation>
        <TopNav id="mobile-nav">
          <MenuBox>
            <Button kind="link" onClick={() => setSecondaryMenuOpen(true)}>
              <Box
                bg="none"
                padding="sm"
                border="border"
                borders={['right']}
                borderRadius={false}
              >
                <Logo>{getMenuLogo()}</Logo>
                <Burger />
                <ScreenReader>Toggle Menu</ScreenReader>
              </Box>
            </Button>
          </MenuBox>
          <Main>
            <Truncate maxWidth="100%">{title}</Truncate>
            <Logo style={{ marginLeft: '8px' }}>{getHeaderLogo()}</Logo>
          </Main>
        </TopNav>
        <MobileSideMenu
          open={secondaryMenuOpen}
          onToggle={setSecondaryMenuOpen}
        >
          <TopLevel
            onExpandSettings={() => {
              setLevel(SETTINGS)
              setSecondaryMenuOpen(false)
            }}
            onExpandSwitchSite={() => {
              setLevel(SWITCH_SITES)
              setSecondaryMenuOpen(false)
            }}
            onExpandUser={() => {
              setLevel(USER)
              setSecondaryMenuOpen(false)
            }}
          >
            <Button kind="icon" onClick={() => setSecondaryMenuOpen(false)}>
              <Icon kind="doubleCaretLeft" color="bodyLt" />
            </Button>
          </TopLevel>
        </MobileSideMenu>
        <MobileSideMenu open={level > NONE} onToggle={() => setLevel(NONE)}>
          <Box bg="none" style={{ width: EXPANDED_WIDTH }}>
            {level === USER && <UserLevel onReturn={() => setLevel(NONE)} />}
            {level === SETTINGS && (
              <SettingsLevel onReturn={() => setLevel(NONE)} />
            )}
            {level === SWITCH_SITES && (
              <SwitchSitesLevel onReturn={() => setLevel(NONE)} />
            )}
            <Button
              kind="link"
              color="bodyLt"
              onClick={() => {
                setLevel(NONE)
                setSecondaryMenuOpen(true)
              }}
              size="md"
              style={{ fontWeight: 'normal', margin: '24px 40px' }}
            >
              <Icon kind="arrowLeft" color="bodyLt" size=".5em" />
              {' Back'}
            </Button>
          </Box>
        </MobileSideMenu>
      </HideableNavigation>
      <Box padding="3vw">
        <MainView>
          {children}
          <SiteFooter />
        </MainView>
      </Box>
    </div>
  )
}

const Logo = styled.div`
  padding: 0 0 12px;
  svg {
    display: block;
    width: 3em;
  }
`
Logo.displayName = 'Nav(Logo)'
