import {
  differenceInYears,
  endOfMonth,
  format,
  isValid,
  startOfDay,
  startOfMonth,
  startOfYear,
  subDays,
  subMonths,
  subYears
} from 'date-fns'
import { Range, ComparisonRange, DateParams, FormatType } from './types'

export const getDaysAgo = (days: number, ref = new Date()) => {
  return startOfDay(subDays(ref, days))
}

export const getStartOfMonth = (): Date => {
  return startOfMonth(new Date())
}

export const getStartOfLastMonth = (ref?: Date): Date => {
  return startOfMonth(subMonths(ref ? ref : new Date(), 1))
}

export const getEndOfLastMonth = (): Date => {
  return endOfMonth(subMonths(new Date(), 1))
}

export const getStartOfYear = (): Date => {
  return startOfYear(new Date())
}

interface PrevRangeArgs extends Partial<DateParams> {
  primaryRange: Range
  comparisonRange?: ComparisonRange
}

export const getPrevRange = ({
  start,
  end,
  primaryRange,
  comparisonRange,
  compare_start,
  compare_end
}: PrevRangeArgs): { start: Date; end: Date } => {
  if (!start || !end)
    throw new Error('BUG: Trying to get prev range for nonexistent dates')

  if (comparisonRange === 'Custom') {
    return {
      start: compare_start as Date,
      end: compare_end as Date
    }
  }

  if (comparisonRange === 'Last Year') {
    return {
      start: subYears(start, 1),
      end: subYears(end, 1)
    }
  }

  if (comparisonRange === 'Last Period') {
    if (primaryRange === 'Month-to-Date') {
      return {
        start: startOfMonth(subMonths(start, 1)),
        end: subMonths(end, 1)
      }
    }

    if (primaryRange === 'Year-to-Date') {
      return {
        start: startOfYear(subYears(start, 1)),
        end: subYears(end, 1)
      }
    }

    if (primaryRange === 'Last Month') {
      return {
        start: startOfMonth(subMonths(start, 1)),
        end: subDays(start, 1)
      }
    }

    const d = end.getTime() - start.getTime()
    return {
      start: subDays(start, d / (24 * 3600 * 1000) + 1),
      end: subDays(start, 1)
    }
  }

  return { start, end }
}

export const formatDate = (date: Date | string, formatType: FormatType) => {
  if (typeof date === 'string') {
    date = new Date(date)
  }

  if (!isValid(date)) {
    return ''
  }

  switch (formatType) {
    case 'input':
      // This is used to get dates that are compatible with date inputs.
      return format(date, 'yyyy-MM-dd')
    case 'long':
      return format(date, 'MMMM d, yyyy')
    case 'short':
      return format(date, 'MMM d, yyyy')
    case 'withoutYear':
      return format(date, 'MMMM d')
    case '2DigitDate':
      return format(date, 'MM/dd/yyyy')
    case 'shortMonth':
      return format(date, 'MMM d')
    default:
      return format(date, 'M/d/yyyy')
  }
}

/**
 * Transforms a Date object into a string to be used for requests.
 */
export function normalizeDateForRequest(date: Date | undefined) {
  if (!date) throw Error('BUG: trying to make a request without a date.')
  return format(date, 'M/d/yyyy')
}

export function splitPrimaryAndComparison<T extends { date: string }>(
  results: T[],
  params: DateParams
): { primary: T[]; comparison?: T[] } {
  // If there isn't a start or end parameter, the request
  // wasn't made for a date range and we can just assume
  // the results are the only data.
  if (!params.start || !params.end) {
    return { primary: results }
  }

  const primary = results.filter((item) => {
    // Some API endpoints might return date as YYYY-MM-DD, which
    // is off by one for date ranges after GMT because JS interprets
    // these date as UTC, relative to the user's timezone. Replacing
    // hyphens with slashes fixes the issue.
    const date = new Date(item.date.replace(/-/g, '/'))
    return date >= (params.start as Date) && date <= (params.end as Date)
  })

  if (!params.compare_start || !params.compare_end) {
    return { primary }
  }

  const comparison = results.filter((item) => {
    // Same logic as above.
    const date = new Date(item.date.replace(/-/g, '/'))
    return (
      // within bounds of comparison
      date >= (params.compare_start as Date) &&
      date <= (params.compare_end as Date) &&
      // outside of bounds of primary
      (date < (params.start as Date) || date > (params.end as Date))
    )
  })

  return { primary, comparison }
}

/**
 * Given arbitrary start and compare_start params,
 * determine if a comparison is yearly.
 */
export function isYearlyComparison(params: DateParams) {
  const { start, compare_start } = params
  if (!start || !compare_start) return false
  // If ranges are at least a year apart, we can assume we're looking at a year-over-year comparison
  return differenceInYears(start, compare_start) >= 1
}

/**
 * Proxies the global Date object to
 * simulate using the app on a specific date.
 */
export function __DEV_ONLY__simulateDate(value: string) {
  window.Date = new Proxy(Date, {
    construct: function (target, args) {
      if (args.length === 0) {
        return new target(value)
      }
      // @ts-ignore
      return new target(...args)
    }
  })
}

/**
 * Utility function to allow converting a date or string to a specific timezone
 */
export function dateToTimezone(date: string | Date, timezoneStr: string) {
  return new Date(
    (typeof date === 'string' ? new Date(date) : date).toLocaleString('en-US', {
      timeZone: timezoneStr
    })
  )
}
